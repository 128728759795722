@import "./constants";

.flex-container {
  display: flex;
  flex-direction: row;
}

.back-red {
  margin-top: 6px;
  background-color: red;
  padding: 1px;
}

.custom-select {
  width: 256px;
  margin-left: 10px;
}

.contact-info-td {
  border-bottom: 1px solid black;
  padding: 10px;
}
.fieldgroup.collapsed .fieldgroup-body {
  display: none;
}
.fieldgroup.expanded .fieldgroup-body {
  display: block;
}
.heightWith {
  height: 1px; width: 1px; border: none; background-color: transparent;
}
.marginzero {
  margin: 0;
}
