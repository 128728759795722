.se-container {


  strong {
    font-weight: bold !important;
  }

  em {
    font-style: italic;
  }

  em {
    strong {
      font-weight: bold;
      font-style: italic;
    }
  }

  strong {
    em {
      font-weight: bold;
      font-style: italic;
    }
  }

  h1 {
    font-size: 2.5rem;
  }

  h2 {
    font-size: 2rem;
    font-weight: 500 !important;
  }

  h3{
    font-size: 1.75rem;
  }
  h4{
    font-size: 1.5rem;
  }
  h5{
    font-size: 1.25rem;
  }
  h6{
    font-size: 13px;
  }
}