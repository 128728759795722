.custom-modal {
    display: none;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -0%);
    background: #fff;
    width: 250px;
    height: 250px;
    border: 1px solid #ccc;
    border-radius: 3px;
    padding: 3px;
    z-index: 100;
}

.bg-disable {
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    z-index: 99;
    background-color: rgba(0, 0, 0, 0.2);
}

.modal-active {
    display: block;
}

.modal-bar {
    display: flex;
    flex-direction: row-reverse;
    background: #5d819d;
    padding: 4px;
    border-radius: 3px;
}

.modal-close {
    display: inline-block;
    background: #fff;
    padding: 2px 4px;
    border-radius: 3px;
    cursor: pointer;
}

.modal-content {
    padding: 10px;
}