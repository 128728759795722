html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p,
	blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn,
	em, font, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup,
	tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label,
	legend, table, caption, tbody, tfoot, thead, tr, th, td {
	border: 0;
	margin: 0;
	outline: 0;
	padding: 0;
	font-size: 100%;
	background: transparent;
	vertical-align: baseline;
}

blockquote, body, div, dl, dt, dd, fieldset, form, h1, h2, h3, h4, h5,
	h6, img, input, li, ol, p, select, span, td, textarea, th, ul {
	border-style: none;
	margin: 0;
	padding: 0;
}

a, address, body, caption, cite, code, dfn, em, strong, th, var {
	font-style: normal;
	font-weight: normal;
	text-decoration: none;
}

body, caption, th {
	text-align: left;
}

ol, ul {
	list-style: none;
}

ol.numbers {
	list-style: decimal;
	padding-left: 20px;
}

ul.dots {
	list-style: circle;
	padding-left: 20px;
}

/* ------------------------------ */
/*      Basic Element Styles      */
/* ------------------------------ */
a, a:visited {
	color: #333;
	font-weight: bold;
}

a:hover, a:active {
	text-decoration: underline;
}

.disabled {
	color: #333366;
	text-decoration: none;
}

body, textarea, input, select {
	font-family: Verdana, Arial, Helvetica, sans-serif;
}

html {
	background-image: url(../images/tile.gif);
}

body {
	font-size: 62.5%;
	position: relative;
	color: #000;
	min-width: 780px;
	margin: 0;
	/* width: expression(document.body.clientWidth < 782 ? "780px" : "auto"); */
}

hr {
	background-color: #ccc;
	color: #ccc;
	border-bottom: #fafafa 0.1em solid;
	border-right: #fafafa 0.2em solid;
	border-left: #d9d9d9 0.2em solid;
	border-top: #d9d9d9 0.1em solid;
	margin: 0.5em 0 0.5em 0;
}

h1, .h1 {
	font-size: 1.6em;
	font-weight: bold;
}

h2, .h2 {
	font-size: 1.3em;
	font-weight: bold;
}

h3, .h3 {
	font-size: 1.1em;
	font-weight: bold;
}

/* IE7 fix */
table {
	font-size: 1em;
}

/* IE7 fix */
table input {
	border: #999999 solid 0.1em;
}

textarea {
	background-color: #ffffff;
	border: #999999 solid 0.1em;
	font-size: 1.1em;
}

th, th a {
	color: #000000;
	font-weight: bold;
}

/* -------------------------- */
/*     Float Clear/Reset      */
/* -------------------------- */
.clear {
	clear: both;
	height: 0;
	visibility: hidden;
}

.no-clear .clear {
	/* Used in container elements to override
 the clear class in contained elements. */
	clear: none;
}

/* ------------------------------- */
/*      Single Element Styles      */
/* ------------------------------- */
.alert {
	/* Used for alert text */
	color: #ff0000;
}

.content-messages p {
	margin: 0.5em 0 0.5em 0;
}

.hidden {
	height: 0;
	visibility: hidden;
}

.label {
	/* Used for <input> element labels */
	font-weight: bold;
	height: 1.2em; /* Same as <input> height */
	padding-right: 1.1em;
	text-align: right;
	white-space: nowrap;
	width: 1%;
	vertical-align: middle;
}

.required, input.required {
	/* required style for <input> elements */
	background-color: #ffffdd;
}

.tooltip {
	color: #557996;
	font-style: italic;
	margin: 0.4em;
}

.visible {
	height: auto;
	visibility: visible;
}

.page-container {
	/*background: url("images/big-fade.png") repeat-x 0 90px transparent;*/

}

.align-float {
	float: right;
}

.align-text {
	text-align: right;
}

.align-top {
	vertical-align: top;
}

.centered {
	text-align: center;
}

.page-title {
	color: #557996;
	margin-bottom: 0.5em;
	font-size: 1.6em;
	font-weight: bold;
}

.contentarea {

}

/* ------------------------ */
/*      Masthead Style      */
/* ------------------------ */
#masthead {
	background: #FFF;
	color: #ccc;
	font-size: 1em;
	font-weight: normal;
	min-height: 26px; /* Setting must be in px */
	height: auto;
	overflow: auto;
}

#masthead ul li {
	float: left;
	padding: 0.5em 0.8em 0.5em 0;
}

#masthead ul li a {
	color: #ccc;
	font-weight: normal;
}

.last-system-msg {
	color: #FFFFFF;
	font-size: 0.9em;
	padding-left: 10px;
}

.preference-area {
	float: right !important;
}

#masthead ul .logo-area {
	padding: 0.1em 0.8em;
}

#masthead ul .org-logo-area {
	padding: 0.1em 0.8em;
}

#masthead ul .org-logo-area img {
	width: auto;
	height: 22px;
}

#masthead ul a:focus {
	text-decoration: underline;
}

/* ---------------------- */
/*      Footer Style      */
/* ---------------------- */
#footer {
	background: #333;
	border-top: 0.1em inset #000;
	padding: 0.5em 0 0.5em 0.5em;
}

#footer a {
	color: #ccc;
	font-weight: normal;
}

#footer ul {
	float: left;
	display: inline
}

#footer ul li {
	border-left: 0.1em solid #444;
	border-right: 0.1em solid #222;
	float: left;
	display: inline;
	color: #ccc;
	font-weight: normal;
	padding: 0.5em;
}

#footer ul .first {
	border-left: none;
}

#footer ul .last {
	border-right: none;
}

#footer ul li a {
	color: #ccc;
	font-weight: normal;
}

#footer p {
	clear: left;
	color: #ccc;
	font-weight: normal;
	padding: 0.5em;
}

#footer a:focus {
	text-decoration: underline;
}

/* ------------------------------- */
/*      Main Navigation Style      */
/* ------------------------------- */
#main-navigation {
	clear: both;
	/* background: #436783 url(../images/main-nav.gif) top left repeat-x; */
	background: #990099;
	color: #fff;
	font-size: 1em;
	/*height: 5.2em;
	 overflow: auto; */
	border-bottom: 0.2em solid #990099;
	padding: 0.5em 0 0.1em 0;
}

#main-navigation ul li {
	/* float: left; */
	display: inline;
	/* height: 4.85em; */
	padding: 0 1em 0 0.5em;
	border-left: 0.1em solid #800080	;
	border-right: 0.1em solid #800080;
}

#main-navigation ul .disabled {
	color: #D4D0C8;
}

#main-navigation ul .first {
	border-left: none;
}

#main-navigation ul .last {
	border-right: none;
}

#main-navigation ul a {
	color: #fff;
	/* display: block; */
	padding: 0.5em 0.5em 0.5em 0.5em;
	font-size: 1.2em;
	font-weight: normal;
	text-transform: uppercase;
	/* Uncomment next line for main-nav drop shadows */
	/*text-shadow: 0px 1px 1px #22323f;*/
}

#main-navigation ul a:hover, #main-navigation ul .selected,#main-navigation ul a:hover, #main-navigation ul .selected a {
	color: #F9924B;
	text-decoration: none;
}

#main-navigation ul a:focus {
	text-decoration: underline;
}

/* -------------------------------------- */
/*      Application Navigation Style      */
/* -------------------------------------- */
#app-navigation {
	background: url(../images/gray-texture.jpg) top left;
	border-top: 0.1em solid #3E5A71;
	border-bottom: 0.1em solid #fff;
	height: 1%; /* IE fix */
	margin: 0;
	padding: 0.5em;
}

#app-navigation h1, #app-navigation h2, #app-navigation h3 {
	color: #959595;
	margin: 0.4em 0 0.4em 0;
	text-transform: uppercase;
	text-shadow: 1px 1px 0 #fff; /* Setting must be in px */
}

#app-navigation ul {
	display: inline; /* IE Fix */
}

#app-navigation ul li ul li {
	background-color: #fff;
	border-top: 0.1em solid #ccc;
	border-right: 0.1em solid #ddd;
	border-bottom: 0.1em solid #ddd;
	border-left: 0.1em solid #ccc;
	padding: 0.5em;
	float: left;
	display: inline;
	margin: 0.1em 0.3em 0.1em 0;
}

#app-navigation ul .disabled {
	color: #D4D0C8;
}

#app-navigation ul a {
	color: #444;
	display: block;
	font-weight: normal;
	padding: 0.1em 0.3em 0.1em 0.3em;
}

#app-navigation ul .selected, #app-navigation ul .selected {
	background-color: #f5f5f5;
	border: 0.1em solid;
	border-color: #aaa #f9f9f9 #f9f9f9 #aaa;
	color: #ffffff;
}

#app-navigation ul .selected a {
	color: #111;
	padding: 0.1em 0.3em 0 0.3em;
}

#app-navigation ul a:hover {
	text-decoration: none;
	color: #111;
}

#app-navigation ul a:focus {
	text-decoration: underline;
}

/* ------------------------- */
/*      Screenlet Style      */
/* ------------------------- */
.screenlet {
	background-color: #FFFFFF;
	border-right: 0.1em solid #ccc;
	border-bottom: 0.2em solid #ccc;
	border-left: 0.1em solid #ccc;
	height: auto !important;
	height: 1%;
	margin-bottom: 1em;
	overflow: auto;
}

.screenlet-body {
	background-color: #FFFFFF;
	height: auto !important;
	height: 1%;
	padding: 0.4em;
}

.screenlet-body div {
	margin: 0.8em 0.1em
}

/* Special Screenlet style for locale and timezone window */
.lists.screenlet {
	margin-left: 25%;
	margin-right: 25%;
	margin-top: 1em;
}

.lists.screenlet .basic-table tr td {
	text-align: center;
}

.lists.screenlet .basic-table tr td a {
	display: block;
}

/* No padding screenlet decorator */
.no-padding {
	padding: 0;
	border: none;
}

.no-padding .h1, .no-padding .h2, .no-padding .h3, .no-paddingr h1,
	.no-padding h2, .no-padding h3 {
	padding: 0.7em;
}

/* ----------------------------------- */
/*      Screenlet Title Bar Style      */
/* ----------------------------------- */
.screenlet-title-bar {
	background: url(../images/bhead.gif) top left repeat-x;
	color: #ffffff;
	height: 1%; /* IE fix */
	min-height: 1.5em;
	padding: 0.8em 0.2em;
}

.screenlet-title-bar .h1, .screenlet-title-bar .h2, .screenlet-title-bar .h3,
	.screenlet-title-bar h1, .screenlet-title-bar h2, .screenlet-title-bar h3
	{
	background: none;
	color: #333;
	text-shadow: 1px 1px 0 #fff; /* Setting must be in px */
}

.screenlet-title-bar ul {
	display: inline; /* IE Fix */
}

.screenlet-title-bar ul li {
	background: none;
	border-left: 0.1em solid #dedede;
	color: #222;
	float: right;
	font-size: 1em;
	font-weight: bold;
	padding: 0.1em 0.5em 0.2em 0.5em;
}

.screenlet-title-bar ul .h1, .screenlet-title-bar ul .h2,
	.screenlet-title-bar ul .h3 {
	border-left: none;
	float: left;
}

.screenlet-title-bar h1, .screenlet-title-bar .h1 {
	font-size: 1.6em;
}

.screenlet-title-bar h2, .screenlet-title-bar .h2 {
	font-size: 1.3em;
}

.screenlet-title-bar h3, .screenlet-title-bar .h3 {
	font-size: 1.1em;
}

.screenlet-title-bar .h3 a {
	color: #222;
	display: inline;
}

.screenlet-title-bar ul a {
	color: #222;
	display: block;
}

.screenlet-title-bar ul a:visited {
	color: #222;
}

.screenlet-title-bar ul a:hover {
	color: #ffffff;
	text-decoration: none;
}

.screenlet-title-bar ul .disabled {
	color: #CCCCCC;
}

.screenlet-title-bar ul .collapsed, .screenlet-title-bar ul .collapsed:hover
	{
	background: url(../images/expand.gif) no-repeat center center;
	min-height: 1.1em;
	min-width: 1.1em;
}

.screenlet-title-bar ul .expanded, .screenlet-title-bar ul .expanded:hover
	{
	background: url(../images/collapse.gif) no-repeat center center;
	min-height: 1.1em;
	min-width: 1.1em;
}

.screenlet-title-bar ul .collapsed a, .screenlet-title-bar ul .expanded a
	{
	/* IE fix */
	cursor: default;
}

/* login screenlet decorator */
.login-screenlet {
	min-width: 25em;
	width: 25%;
}
.ml-2 {
	margin-left: 3%;
}
.login-screenlet .screenlet-title-bar {
	text-align: center;
}

/* ------------------------------------------------ */
/*      Basic Navigation (Vertical Menu) Style      */
/* ------------------------------------------------ */
.basic-nav {
	/* indent menu - helpful for widgets */
	padding-left: 1em;
}

.basic-nav ul a {
	color: #333;
	font-size: 1.1em;
}

.basic-nav ul .selected, .basic-nav ul .selected a {
	color: #0000ff;
}

.basic-nav ul a:hover {
	color: #0000ff;
}

.basic-nav ul .disabled, .basic-nav ul .disabled a {
	color: #D4D0C8;
}

/* ------------------------------------- */
/*      Button Bar Navigation Style      */
/* ------------------------------------- */
.button-bar {
	margin-bottom: 1.0em;
	width: 100%;
}

.button-bar ul {
	display: inline; /* IE Fix */
}

.button-bar form, .button-bar ul li ul li {
	float: left;
	font-weight: bold;
}

.button-bar ul .opposed {
	float: right;
}

.button-bar ul a {
	display: block;
}

.button-bar .opposed, .button-bar .opposed li, .button-bar ul .opposed {
	float: right;
}

/* ------------------------------- */
/*      Button Bar Decorators      */
/* ------------------------------- */
.button-style-1 {
	display: table;
}

.button-style-1 ul li ul li {
	margin-right: 0.5em;
	margin-bottom: 0.5em;
}

.button-style-2 {
	display: table;
}

.button-style-2 ul li ul li {
	margin-right: 0.5em;
	margin-bottom: 0.5em;
}

.button-style-2 {
	display: table;
}

/* ===== In line bar ===== */
.in-line-bar ul {
	display: inline; /* IE Fix */
}

.in-line-bar ul li {
	float: left;
	padding: 0 0.5em;
}

.tool-bar {
	padding: 0.2em;
}

.tool-bar ul li {
	padding: 0.1em;
}

.tab-bar {
	display: table;
	margin: 0.5em 0.5em 0 0.5em;
}

.tab-bar ul li ul li {
	margin-right: 0.5em;
	margin-bottom: 0.5em;
	background: #fff;
	border-top: 0.1em solid #ccc;
	border-right: 0.2em solid #ccc;
	border-bottom: 0.2em solid #ccc;
	border-left: 0.1em solid #ccc;
	padding: 0.5em;
	white-space: nowrap;
}

.tab-bar ul a, .tab-bar ul a:visited {
	color: #666;
}

.tab-bar ul a:hover, .tab-bar ul .selected, .tab-bar ul .selected a,
	.tab-bar ul .selected a:hover {
	color: #333;
	text-decoration: none;
}

.tab-bar ul a:focus {
	text-decoration: underline;
}

.tab-bar ul .disabled {
	color: #ccc;
}

/* ---------------------- */
/*      Table Styles      */
/* ---------------------- */
.basic-table {
	background-color: #ffffff;
	color: #000000;
	margin-bottom: 1em;
	width: 100%;
}

form table, form .basic-table, .screenlet-body .basic-table {
	background: transparent;
	margin-bottom: 0;
}

.basic-table tr td {
	/* Style for all cells */
	padding: 0.8em 0.1em;
	vertical-align: middle;
}

.basic-table tr .align-bottom {
	vertical-align: bottom;
}

.basic-table tr .align-top {
	vertical-align: top;
}

.basic-table tr .label, .basic-table tr .group-label {
	/* field labels for forms */
	font-weight: bold;
	text-align: right;
	padding-right: 1.5em;
	white-space: nowrap;
	width: 1%;
}

.basic-table tr .group-label {
	/* "header" for field label groups */
	font-size: 1.2em;
	padding: 2em 1.5em 0 0;
}

.basic-table tr th, .basic-table .header-row {
	font-weight: bold;
	text-align: left;
}

#ticket {
	width: 100%;
}

.ticketSubmit{
	position:absolute;
	margin-left:100px;
	margin-right:100px;
	top:15px;
	display:block;
}

#ticketleftbar {
	width: 18%;
	float: left;
}

#filter {
	overflow: hidden;
    margin-bottom:0px;
    height:auto;
}

#ticketrightbar {
	width: 80%;
	margin-left: 1%;
	display: inline-block;
}

.basic-table .header-row td {
	border-bottom: 0.1em solid #000000;
}

.basic-table .header-row td a {
	color: #000000;
}

.basic-table .alternate-row {
	/* Alternating row style */
	background-color: #eeeeee;
}

.basic-table .selected {
	background: #FFFCCF;
}

.basic-table .alternate-rowSelected {
	background: #FFF55F;
}

.basic-table .Validate {
	/*Style use by alt-row-style on list*/
	background: #A0D5F7;
}

.basic-table .alternate-rowValidate {
	/*Style use by alt-row-style on list*/
	background: #72A8F2;
}

.basic-table .Warn {
	/*Style use by alt-row-style on list*/
	background: #f55C5C;
}

.basic-table .alternate-rowWarn {
	/*Style use by alt-row-style on list*/
	background: #FC7455;
}

.basic-table tr .button-col {
	/* button column style - for the small
collection of buttons used in lists */
	vertical-align: top;
	padding: 0.3em;
}

.basic-table tr .button-col a, .basic-table tr .button-col button,
	.basic-table tr .button-col input[type="reset"], .basic-table tr .button-col input[type="submit"],
	.basic-table tr .button-col input[type="button"] {
	-webkit-appearance: none;
	-moz-border-radius: 1em;
	-webkit-border-radius: 1em;
	border-radius: 1em;
	background: #ddd url(../images/button.png) repeat-x;
	background: -webkit-gradient(linear, left top, left bottom, color-stop(0, #fff),
		color-stop(1, #ddd));
	background: -moz-linear-gradient(top center, #fff 0%, #ddd 100%);
	border: 0.1em solid;
	border-color: #ddd #bbb #999;
	cursor: pointer;
	color: #333;
	display: inline-block;
	font-size: 0.9em;
	font-weight: bold;
	outline: 0;
	overflow: visible;
	padding: 0.1em 0.5em 0.2em 0.5em;
	text-decoration: none;
	text-shadow: #fff 0 1px 1px; /* Setting must be in px */
	/*text-transform:uppercase;*/
	width: auto;
	/* IE7 */
	*padding-top: 0.2em;
	*padding-bottom: 0;
}

.basic-table .collapsed {
	visibility: collapse;
}

/*      Alternate table header style      */
.basic-table .header-row-2 th, .basic-table .header-row-2 td {
	background-color: #999;
	border-bottom: 0;
	border-left: 0.1em solid #aaa;
	border-right: 0.1em solid #888;
	color: #ffffff;
	font-weight: bold;
}

/*      Sort field style      */
.basic-table .header-row-2 th .sort-order-asc, .basic-table .header-row-2 td .sort-order-asc
	{
	background: url(../images/arrow-gr-up.png) no-repeat right;
	padding-left: 0.5em;
	padding-right: 20px; /* Setting must be in px */
}

.basic-table .header-row-2 th .sort-order-desc, .basic-table .header-row-2 td .sort-order-desc
	{
	background: url(../images/arrow-gr-dw.png) no-repeat right;
	padding-left: 0.5em;
	padding-right: 20px; /* Setting must be in px */
}

.basic-table .header-row-2 th .sort-order, .basic-table .header-row-2 td .sort-order
	{
	background: url(../images/arrow-gr.png) no-repeat right;
	padding-left: 0.5em;
	padding-right: 20px; /* Setting must be in px */
	color: #fff
}

/* ------------------------------------- */
/*      Table decorator - Hover bar      */
/* ------------------------------------- */
.hover-bar tr:hover {
	background-color: #cccccc;
	cursor: default;
}

.hover-bar .header-row-1:hover, .hover-bar tr th:hover {
	background-color: #ffffff;
	color: #000000;
}

/* -------------------------------- */
/*      Table decorator - Grid      */
/* -------------------------------- */
.dark-grid {
	border-right: 0.1em solid #000000;
	border-top: 0.1em solid #000000;
}

.dark-grid td, .dark-grid .header-row-2 th, .dark-grid .header-row-2 td
	{
	border-bottom: 0.1em solid #000000;
	border-left: 0.1em solid #000000;
}

.light-grid {
	border-right: 0.1em solid #cccccc;
	border-top: 0.1em solid #cccccc;
}

.light-grid td, .light-grid .header-row td {
	border-bottom: 0.1em solid #cccccc;
	border-left: 0.1em solid #cccccc;
}

/* ------------------------------------ */
/*      Table decorator - Calendar      */
/* ------------------------------------ */
.calendar {
	border-right: 0.1em solid #cccccc;
}

.calendar tr td {
	border-bottom: 0.1em solid #cccccc;
	border-left: 0.1em solid #cccccc;
	vertical-align: top;
	padding: 0.5em;
}

.calendar .header-row td {
	background-color: #47637c;
	border-bottom: 0.1em solid #cccccc;
	border-left: 0.1em solid #cccccc;
	color: #ffffff;
	height: auto;
	text-align: center;
}

.calendar tr td .add-new {
	float: right;
}

.calendar tr td .h1 {
	color: #333;
	float: left;
}

.calendar .current-period {
	background-color: #ffffcc;
}

.calendar .active-period {
	background-color: #eeeeee;
}

/*     ================== */
/*      Tree Styles      */
/*     ================== */
.basic-tree {
	/* placeholder */

}

.basic-tree ul {
	padding-left: 1em;
}

.basic-tree li {
	padding-left: 1em;
	white-space: nowrap;
}

.basic-tree li .expanded {
	background: url(../images/collapse.gif) no-repeat left center;
	padding-right: 1em;
}

.basic-tree li .collapsed {
	background: url(../images/expand.gif) no-repeat left center;
	padding-right: 1em;
}

.basic-tree li .leafnode {
	background: url(../images/expand-collapse-placeholder.gif) no-repeat left
		center;
	padding-right: 1em;
}

.basic-tree li .treeitem {
	/* treeitem is deprecated */
	border-style: none;
	color: #333;
	font-size: 1em;
}

.basic-tree li a:hover {
	color: #0000ff;
}

/* --------------------- */
/*      Form Styles      */
/* --------------------- */
.basic-form {
	margin-bottom: 1em;
}

.basic-form table {
	width: 100%;
}

.basic-form table .header-row:hover {
	/*background-color: #ffffff;*/
	color: #000000;
}

.basic-form table tr td {
	padding: 0.1em;
}

.basic-form table tr .label, .basic-form table tr .group-label {
	/* field labels for forms */
	font-weight: bold;
	text-align: right;
	padding-right: 1.5em;
	width: 1%;
}

.basic-form table tr .group-label {
	/* "header" for field label groups */
	font-size: 1.2em;
	padding: 2em 1.5em 0 0;
}

.basic-form table tr th, .basic-form table .header-row {
	font-weight: bold;
	text-align: left;
}

.basic-form table .header-row:hover, .basic-form table tr th:hover {
	/*background-color: #ffffff;*/
	color: #000000;
}

.basic-form table .header-row td {
	border-bottom: 0.1em solid #999999;
}

.basic-form table .alternate-row {
	/* Alternating row style */
	/*background-color: #eeeeee;*/

}

.basic-form table tr td .disabled {
	/*background-color: #cccccc;*/
	color: #333366;
	text-decoration: none;
}

/*      Form decorator - Form Widget      */
.form-widget table tr .label, .form-widget-table tr .label {
	/* Added for backward compatibility */
	width: 20%;
}

.form-widget table, .form-widget-table {
	width: auto;
}

.form-widget-table {
	margin-bottom: 1em;
}

.basic-form table.condensed-table {
	width: 0%;
}

/* ------------------------------- */
/*      List Navigation Style      */
/* ------------------------------- */
.nav-pager {
	background-color: #333;
	color: #ffffff;
	font-weight: bold;
	line-height: 3.5em;
	height: 3.5em;
	margin: 0.5em 0 0.5em 0;
}

.nav-pager ul {
	display: inline; /* IE Fix */
}

.nav-pager ul li {
	border-right: solid #222 0.1em;
	border-left: solid #444 0.1em;
	float: left;
	vertical-align: center;
}

.nav-pager ul li a {
	display: block;
}

.nav-pager ul a {
	color: #ffffff;
	padding: 0 1em 0 1em;
	text-decoration: none;
}

.nav-pager ul a:hover {
	background-color: #999;
}

.nav-pager ul .nav-pagesize, .nav-pager ul .nav-page-select, .nav-pager ul .nav-displaying
	{
	padding: 0 1em 0 1em;
}

.nav-pager ul .nav-first-disabled, .nav-pager ul .nav-previous-disabled,
	.nav-pager ul .nav-next-disabled, .nav-pager ul .nav-last-disabled {
	color: #D4D0C8;
	padding: 0 1em 0 1em;
}

.nav-pager ul .nav-displaying {
	border-right: none;
}

/* ------------------------------- */
/*      Visual Embellishments      */
/* ------------------------------- */
.field-lookup a {
	background: url(../images/fieldlookup.gif) no-repeat 0 0;
	display: inline-block;
	vertical-align: top;
	margin-top: 0.3em;
	width: 16px; /* Setting must be in px */
	height: 16px; /* Setting must be in px */
}

/* --------------------------- */
/*      FieldGroup Styles      */
/* --------------------------- */
.fieldgroup-title-bar {
	border-top: 0.1em solid #DADADA;
	font-size: 1em;
	font-weight: bold;
	padding: 0.1em 0.5em 0.2em 0.5em;
}

.fieldgroup-title-bar ul .collapsed, .fieldgroup-title-bar ul .collapsed:hover
	{
	background: url(../images/expand.gif) no-repeat left center;
	min-height: 1.1em;
	min-width: 1.1em;
}

.fieldgroup-title-bar ul .expanded, .fieldgroup-title-bar ul .expanded:hover
	{
	background: url(../images/collapse.gif) no-repeat left center;
	min-height: 1.1em;
	min-width: 1.1em;
}

.fieldgroup-title-bar a {
	cursor: pointer;
	text-decoration: none;
	padding-left: 10px; /* Setting must be in px */
	color: #000000;
}

/* ----------------------------------------- */
/*      Portlet configuration screenlet      */
/* ----------------------------------------- */
.portlet-config {
	background-color: #D4D0C8;
	border: 0.2em solid #D4D0C8;
	margin-bottom: 0.5em;
}

.portlet-config-title-bar .title {
	color: #555555;
	font-size: 1.2em;
	float: left;
}

.portlet-config-title-bar ul {
	display: inline; /* IE Fix */
}

.portlet-config-title-bar ul li {
	float: right;
	font-size: 1em;
	font-weight: bold;
	padding: 0.1em 0.5em 0.2em 0.5em;
}

.portlet-config-title-bar ul li.move-up {
	background: url(../images/arrow-single-up-green.png) no-repeat center
		center;
}

.portlet-config-title-bar ul li.move-down {
	background: url(../images/arrow-single-down-green.png) no-repeat center
		center;
}

.portlet-config-title-bar ul li.move-left {
	background: url(../images/arrow-single-left-green.png) no-repeat center
		center;
}

.portlet-config-title-bar ul li.move-right {
	background: url(../images/arrow-single-right-green.png) no-repeat center
		center;
}

.portlet-config-title-bar ul li.move-top {
	background: url(../images/arrow-end-up-green.png) no-repeat center center;
}

.portlet-config-title-bar ul li.move-bottom {
	background: url(../images/arrow-end-down-green.png) no-repeat center
		center;
}

.portlet-config-title-bar ul li.remove {
	background: url(../images/mini-trash.png) no-repeat center center;
}

.portlet-config-title-bar ul li.edit {
	background: url(../images/mini-edit.png) no-repeat center center;
}

/* == Portal Manager Toolbar == */
#manage-portal-toolbar {
	background-color: #D4D0C8;
	margin-bottom: 0.5em;
}

#manage-portal-toolbar ul li {
	float: left;
	padding: 0.1em 0.5em 0.2em 0.5em;
}

#manage-portal-toolbar #config-on-off, #manage-portal-toolbar #manage-portal-page,
	#manage-portal-toolbar #add-column {
	float: right;
}

#manage-portal-toolbar #portal-page-name {
	font-size: 1.5em;
	font-weight: bold;
}

.manage-portal-column-toolbar #add-portlet,
	.manage-portal-column-toolbar #delete-column,
	.manage-portal-column-toolbar #column-width {
	float: right;
}

/*=================== For the quickadd page =================================*/
.quickaddall {
	float: right;
	padding-bottom: 1em;
	padding-top: 1em;
}

.quickaddtable {
	display: inline-block;
	clear: both;
}

.quickaddtable span {
	display: inline;
	float: left;
	border-right: 0.1em solid #999999;
	border-top: 0.1em solid #999999;
	margin: 0;
	padding: 0.3em;
}

.quickaddtable p {
	clear: left;
	height: 100% !important;
	height: 1em;
}

.quickaddtable span.pid {
	width: 10%;
}

.quickaddtable span.name {
	width: 50%;
	text-align: left;
}

.quickaddtable span.listPrice {
	width: 10%
}

.quickaddtable span.totalPrice {
	width: 10%
}

.quickaddtable span.qty {
	width: 10%
}

/* `Widths
----------------------------------------------------------------------------------------------------*/
.input_tiny {
	width: 50px;
}

.input_small {
	width: 100px;
}

.input_medium {
	width: 150px;
}

.input_large {
	width: 200px;
}

.input_xlarge {
	width: 250px;
}

.input_xxlarge {
	width: 300px;
}

.input_full {
	width: 100%;
}

/*
	Added via JS to <textarea> and class="input_full".
	Applies to IE6, IE7. Other browsers don't need it.
*/
.input_full_wrap {
	display: block;
	padding-right: 8px;
}

/* `UI Consistency
----------------------------------------------------------------------------------------------------*/
::-moz-focus-inner {
	border: 0;
}

input[type="search"]::-webkit-search-decoration {
	display: none;
}

input, button, select, textarea {
	margin: 0;
	vertical-align: middle;
}

a.buttontext, a.buttontextbig, .smallSubmit, .smallSubmit:hover,
	.mediumSubmit, .largeSubmit, .loginButton, .button-style-1 a,
	.button-style-1 ul a, .button-style-2 ul a, .button-style-2 a, .button,
	button, input[type="reset"], input[type="submit"], input[type="button"]
	{
	-webkit-appearance: none;
	-moz-border-radius: 1.1em;
	-webkit-border-radius: 1.1em;
	border-radius: 1.1em;
	background: #ddd url(../images/button.png) repeat-x;
	background: -webkit-gradient(linear, left top, left bottom, color-stop(0, #fff),
		color-stop(1, #ddd));
	background: -moz-linear-gradient(top center, #fff 0%, #ddd 100%);
	border: 0.1em solid;
	border-color: #ddd #bbb #999;
	cursor: pointer;
	color: #333;
	display: inline-block;
	font-size: 1em;
	font-weight: bold;
	outline: 0;
	overflow: visible;
	padding: 0.4em 1em 0.4em;
	text-decoration: none;
	text-shadow: #fff 0 1px 1px; /* Setting must be in px */
	/*text-transform:uppercase;*/
	width: auto;
	/* IE7 */
	*padding-top: 0.2em;
	*padding-bottom: 0;
}

button {
	/* IE7 */
	*padding-top: 0.1em;
	*padding-bottom: 0.1em;
}

a.disabled, button[disabled], input[type="reset"][disabled], input[type="submit"][disabled],
	input[type="button"][disabled] {
	color: #888;
	border-color: #ddd #ddd #ccc;
}

textarea, select, input[type="date"], input[type="datetime"], input[type="datetime-local"],
	input[type="email"], input[type="month"], input[type="number"], input[type="password"],
	input[type="search"], input[type="tel"], input[type="text"], input[type="time"],
	input[type="url"], input[type="week"] {
	-webkit-appearance: none;
	-moz-border-radius: 0;
	-webkit-border-radius: 0;
	border-radius: 0;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	/*background-color: #fff;*/
	border: 0.1em solid;
	border-color: #848484 #c1c1c1 #e1e1e1;
	color: #000;
	outline: 0;
	padding: 0.2em 0.3em;
	height: 1.8em;
	/* IE7 */
	*padding-top: 0.2em;
	*padding-bottom: 0.1em;
	*height: auto;
}

/*
	Separate rule for Firefox.
	Cannot stack with WebKit's.
*/
input.placeholder_text, textarea.placeholder_text {
	color: #888;
}

::-webkit-input-placeholder {
	color: #888;
}

textarea[disabled], select[disabled], input[type="date"][disabled],
	input[type="datetime"][disabled], input[type="datetime-local"][disabled],
	input[type="email"][disabled], input[type="month"][disabled], input[type="number"][disabled],
	input[type="password"][disabled], input[type="search"][disabled], input[type="tel"][disabled],
	input[type="text"][disabled], input[type="time"][disabled], input[type="url"][disabled],
	input[type="week"][disabled] {
	background-color: #eee;
	color: #888;
}

.button-style-1 a:focus, .button-style-1 ul a:focus, .button-style-2 ul a:focus,
	.button-style-2 a:focus, .smallSubmit:focus, button:focus, button:active,
	input:focus, input:active, select:focus, select:active, textarea:focus,
	textarea:active {
	-moz-box-shadow: #5c809c 0 0 5px;
	-webkit-box-shadow: #5c809c 0 0 5px;
	box-shadow: #5c809c 0 0 5px;
	/* for Opera */
	z-index: 1;
}

.is_webkit select {
	/* Tweaks for Safari + Chrome. */
	background-image: url(../images/select_arrow.gif);
	background-repeat: no-repeat;
	background-position: right center;
	padding-right: 20px;
}

textarea, select[multiple] {
	height: auto;
}

select[multiple], .is_webkit select[multiple] {
	background-image: none;
	padding: 0;
}

textarea {
	overflow: auto;
	resize: vertical;
	width: 100%;
}

optgroup {
	color: #000;
	font-style: normal;
	font-weight: normal;
}

/* `IE6
----------------------------------------------------------------------------------------------------*/
.ie6_button, * html button {
	background: #ddd url(../images/button.png) repeat-x;
	border: 0.1em solid;
	border-color: #ddd #bbb #999;
	cursor: pointer;
	color: #333;
	font: bold 1.2em Arial, sans-serif;
	padding: 0.2em 1em 0;
	overflow: visible;
	width: auto;
}

* html button {
	padding-top: 0.1em;
	padding-bottom: 0.1em;
}

.ie6_input, * html textarea, * html select {
	background: #fff;
	border: 0.1em solid;
	border-color: #848484 #c1c1c1 #e1e1e1;
	color: #000;
	padding: 0.2em 0.3em 0.1em;
	font-size: 1.3em;
	font-family: Arial, sans-serif;
	vertical-align: top;
}

* html select {
	margin-top: 0.1em;
}

.placeholder_text, .ie6_input_disabled, .ie6_button_disabled {
	color: #888;
}

.ie6_input_disabled {
	background: #eee;
}

/* ------------------------------- */
/*      Browse Category Style      */
/* ------------------------------- */
.browsecategorylist {
	margin-left: 1em;
}

.browsecategorytext {
	color: #333;
	font-weight: bold;
	padding-left: 10px; /* Setting must be in px */
	background: url(../images/bg_bullet_full_2.gif) no-repeat 0px 0.5em;
}

A.browsecategorybutton {
	color: #333;
}

.browsecategorybuttondisabled {
	color: #333333;
	font-weight: bold;
}

/* ----------------------------------- */
/*      Event/Error Message Style      */
/* ----------------------------------- */
.eventMessage, .errorMessage {
	background-color: #FFFFEE;
	border: solid #000000 0.1em;
	font-size: 1.1em;
	font-weight: bold;
	margin: 1em;
	padding: 1em;
}

.eventMessage {
	color: #333;
}

.errorMessage {
	color: #ff0000;
}

/* ------------------------ */
/*      Product Styles      */
/* ------------------------ */
.basePrice {
	color: #000000;
}

.normalPrice {
	color: #008000;
}

.salePrice {
	color: #ff0000;
}

.product-prevnext {
	text-align: right;
}

.productsummary-container {
	border-top: #999 solid 0.1em;
	margin-top: 0.5em;
	margin-bottom: 0.5em;
}

.productsummary {
	border-bottom: #999 solid 0.1em;
	/* using min-height as an alternative to clear: both; because that, in some browsers, will clear the top-level columns and not just in this div (Mozilla based, namely) */
	min-height: 6em;
	overflow: visible;
	padding: 0.5em;
	position: relative;
}

.productsummary .smallimage {
	float: left;
	/* position: absolute; top: 5px; left: 5px; */
	margin: 0 0.5em 0.5em 0;
}

.productsummary .smallimage img {
	border: #CCC solid 0.1em;
	height: 5em;
}

.productsummary .productbuy {
	float: right;
	margin: 0.5em;
	text-align: right;
	width: 13em;
}

.productsummary .productinfo {
	/* margin-left: 60px; */
	text-align: left;
}

#productdetail #long-description {
	border-top: #999 solid 0.1em;
	margin-top: 1em;
	padding-top: 0.5em;
}

.order-items.basic-table tr {
	line-height: 24px;
}

#productdetail #reviews {
	border-top: #999 solid 0.1em;
	margin-top: 1em;
	padding-top: 0.5em;
}

#productdetail #associated-products {
	border-top: #999 solid 0.1em;
	margin-top: 1em;
	padding-top: 0.5em;
}

.treeWrapper {
	background-color: #ffffff;
	border-top: 0.1em solid #000000;
	border-left: 0.1em solid #000000;
	color: #000000;
	font-size: 1.6em;
	font-weight: normal;
	margin-left: 1.2em;
	margin-top: 0.2em;
	text-decoration: none;
}

/* ------------------------- */
/*      HTML Log Styles      */
/* ------------------------- */
.DEBUG {
	color: #808080;
}

.WARN {
	color: #0000ff;
	font-weight: bold;
}

.INFO {
	color: #008000;
}

.ERROR {
	border: 0.2em solid #ff0000;
	color: #ff0000;
	font-weight: bold;
}

/* ---------------------------- */
/*     Multi-Column Styles      */
/* ---------------------------- */
#column-container {
	margin: auto;
	padding: 1em;
	position: relative;
}

#column-container h1, #column-container .h1, #column-container h2,
	#column-container .h2 {
	margin-bottom: 0.5em;
}

#column-container h1, #column-container .h1 {
	color: #557996;
}

#column-container .left {
	float: left;
	/* alt: position: absolute; top: 0px; left: 0px; */
	width: 22em;
	margin-right: 1em;
}

#column-container .left-larger {
	float: left;
	/* alt: position: absolute; top: 0px; left: 0px; */
	width: 25em;
	margin-right: 1em;
}

.lefthalf {
	float: left;
	height: 1%;
	left: 0;
	margin: 0% 1% 1% 0%;
	width: 49%;
}

.righthalf {
	float: right;
	height: 1%;
	margin: 0 0 1% 1%;
	right: 0;
	width: 49%;
}

#column-container .right {
	float: right;
	margin-left: 1em;
	width: 22em;
}

#column-container .leftonly {
	margin-left: 23em;
	width: auto;
}

#column-container .leftonly-larger {
	margin-left: 26em;
	width: auto;
}

#column-container .rightonly {
	margin-right: 23em;
	width: auto;
}

#column-container .center {
	margin-left: 23em;
	margin-right: 23em;
	width: auto;
}

#column-container .nocolumns {
	width: auto;
}

/* ------------------------------ */
/* Deprecated styles - DO NOT USE */
/* ------------------------------ */
.apptitle {
	background-color: #333;
	color: #FFFFFF;
	display: block;
	font-size: 1.3em;
	font-weight: bold;
	line-height: 2em;
	width: 100%;
}

.boxhead {
	color: #FFFFFF;
	font-size: 1.1em;
	font-weight: bold;
}

.boxlink {
	float: right;
}

DIV.boxtop {
	background-color: #333;
	border-color: #999999;
	border-style: solid;
	margin: 0 auto 0 auto;
	padding: 0.1em 0.4em 0.1em 0.4em;
	text-align: center;
}

DIV.boxhead-left {
	border: none;
	color: #FFFFFF;
	float: left;
	font-size: 1.1em;
	font-weight: bold;
	width: auto;
}

DIV.boxhead-fill {
	border: none;
	color: #FFFFFF;
	font-size: 1.1em;
	font-weight: bold;
	text-align: center;
	width: 0;
}

DIV.boxhead-right {
	border: none;
	color: #FFFFFF;
	float: right;
	font-size: 1.1em;
	font-weight: bold;
	width: auto;
}

A.boxheadbutton {
	color: #FFFFFF;
	font-size: 1.1em;
}

A.boxheadbutton:hover {
	color: #ff0000;
	text-decoration: none;
}

.boxoutside {
	background-color: #FFFFFF;
	border: 0.1em solid #999999;
}

.boxtop {
	background-color: #333;
	border: 0 solid #999999;
	padding: 0.1em 0.4em 0.1em 0.4em;
}

.boxbottom {
	background-color: #FFFFFF;
	border: 0 solid #999999;
	padding: 0.4em;
}

.webToolList h3 {
	margin: 0.75em 0 0.09em 0;
	text-transform: uppercase;
}

A.linktext {
	color: #333;
}

A.linktext:hover {
	color: #ff0000;
}

.buttontextdisabled {
	color: #333333;
}

.submenutextinfo {
	border-right: 0.1em solid #5886C6;
	color: #ffffff;
	font-size: 0.9em;
	font-weight: bold;
	padding-left: 0.5em;
	padding-right: 0.5em;
}

.submenutext, A.submenutext, A.submenutext:visited {
	border-right: 0.1em solid #5886C6;
	color: #ffff00;
	font-size: 0.9em;
	font-weight: bold;
	padding-left: 0.2em;
	padding-right: 0.5em;
}

A.submenutext:hover {
	color: #ffff00;
}

.submenutextdisabled, A.submenutextdisabled {
	border-right: 0.1em solid #5886C6;
	color: #CCCCCC;
	font-size: 0.9em;
	font-weight: bold;
	padding-left: 0.2em;
	padding-right: 0.5em;
}

A.submenutextdisabled:hover {
	color: #ffff00;
}

.submenutextinforight {
	color: #ffffff;
	font-size: 0.9em;
	font-weight: bold;
	padding-left: 0.2em;
}

.submenutextright, A.submenutextright {
	color: #ffff00;
	font-size: 0.9em;
	font-weight: bold;
	padding-left: 0.2em;
}

A.submenutextright:hover {
	color: #ffff00;
}

.submenutextrightdisabled, A.submenutextrightdisabled {
	color: #CCCCCC;
	font-size: 0.9em;
	font-weight: bold;
	padding-left: 0.2em;
}

A.submenutextrightdisabled:hover {
	color: #ffff00;
}

A.lightbuttontext {
	border: none;
	color: #222;
}

A.lightbuttontext:hover {
	color: #ccc;
}

A.lightbuttontextsmall {
	border: none;
	color: #222;
	font-size: 0.8em;
}

A.lightbuttontextsmall:hover {
	color: #ccc;
}

.lightbuttontextdisabled {
	color: #CCCCCC;
	font-weight: bold;
}

DIV.row {
	background-color: #B4B0AA;
	margin: 0 auto 0 auto;
	text-align: center;
}

DIV.col {
	border: none;
	float: left;
	width: auto;
}

DIV.col-fill {
	background-color: #B4B0AA;
	border: none;
	color: #333;
	font-weight: bold;
	text-align: center;
	width: 0;
}

DIV.col-right {
	border: none;
	float: right;
	width: auto;
}

.leftclear {
	clear: left;
	height: 1%;
}

DIV.endcolumns {
	/* deprecated - use .clear */
	clear: both;
}

DIV.simple-right-small {
	float: right;
	width: 2em;
	text-align: right;
}

DIV.simple-right-half {
	float: right;
	width: 50%;
	text-align: right;
}

.left-border {
	float: left;
	margin-right: 1em;
	width: 22em;
	border-right: 0.5em dotted #ccc;
	background-color: #eee;
	height: 100%;
}

DIV.widget-container {
	margin-bottom: 2em;
}

DIV.column-left-wide {
	background-color: #CCCCCC;
	border: #000000 solid 0.2em;
	float: left;
	overflow: auto;
	width: 30em;
}

img.cssImgXLarge {
	/*border: 1px black solid;*/
	max-width: 200px;
	max-height: 150px;
}

img.cssImgLarge {
	/*border: 1px black solid;*/
	max-width: 100px;
	max-height: 75px;
}

img.cssImgStandard {
	/*border: 1px black solid;*/
	max-width: 70px;
	max-height: 52px;
}

img.cssImgSmall {
	/*border: 1px black solid;*/
	max-width: 50px;
	max-height: 37px;
}

/* From http://jqueryui.com/demos/autocomplete/maxheight.html */
/* When displaying a long list of options, you can simply set the max-height for the autocomplete menu to prevent the menu from growing too large */
.ui-autocomplete {
	max-height: 250px;
	overflow-y: auto;
	/* prevent horizontal scrollbar */
	overflow-x: hidden;
	/* add padding to account for vertical scrollbar */
	padding-right: 20px;
}
/* IE 6 doesn't support max-height
 * we use height instead, but this forces the menu to always be this tall
 */
* html .ui-autocomplete {
	height: 250px;
}

/* The custom CSS for adjust the JsTree */
.jstree-default .jstree-leaf>ins {
	background-position: -36px 0;
	vertical-align: top;
}

html> /**/ body .jstree-default a {
	white-space: normal !important;
	height: auto;
}
/* IE6 and below */
* html .jstree-default a {
	white-space: normal;
	height: auto;
}
/* IE7 */
*+html .jstree-default a {
	white-space: normal;
	height: auto;
}


/* 

	This is javascript.css

*/

/*
 * Licensed to the Apache Software Foundation (ASF) under one
 * or more contributor license agreements.  See the NOTICE file
 * distributed with this work for additional information
 * regarding copyright ownership.  The ASF licenses this file
 * to you under the Apache License, Version 2.0 (the
 * "License"); you may not use this file except in compliance
 * with the License.  You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */

/* ----------------------------------------------------------------------- */
/* This CSS file is used for the Flat Grey visual theme JavaScript styles. */
/* ----------------------------------------------------------------------- */

#wait-spinner {
	z-index:50000;
	position:absolute;
	}
	
	#wait-spinner-image {
	height:45px;
	width:45px;
	background-repeat: no-repeat;
	background-image:url(../images/spinner.gif);
	}
	
	.wait-spinner div {
		padding-top: 4px;
	}
	
	/***********************************************
	ajax auto complete
	***********************************************/
	div.autocomplete {
	  position:absolute;
	  width:auto !important;
	/* min-width:154px; */
	  background-color:#f8f8f8;
	  border:1px solid #999999;
	  margin:0;
	  padding:0;
	}
	div.autocomplete ul {
	  list-style-type:none;
	  margin:0;
	  padding:0;
	}
	div.autocomplete ul li.selected {
	 background-color: #ffb;
	}
	div.autocomplete ul li {
	  list-style-type:none;
	  display:block;
	  float: none;
	  margin:0;
	  padding:2px;
	  /*height:32px;*/
	  cursor:pointer;
	}
	
	.indicator img {
	  background: url(../images/ajax-loader.gif) no-repeat 0 0;
	  display: block;
	  margin:3px 10px 0px 24px;
	  width:16px;
	  height:16px;
	  border: none;
	}
	
	.screenlet-title-bar .indicator img {
	  float:right;
	  margin:0 3px;
	  width:16px;
	  height:16px;
	  display:inline;
	}
	
	/* Layout helpers
	----------------------------------*/
	.ui-helper-hidden { display: none; }
	.ui-helper-hidden-accessible { position: absolute !important; clip: rect(1px 1px 1px 1px); clip: rect(1px,1px,1px,1px); }
	.ui-helper-reset { margin: 0; padding: 0; border: 0; outline: 0; line-height: 1.3; text-decoration: none; font-size: 100%; list-style: none; }
	.ui-helper-clearfix:before, .ui-helper-clearfix:after { content: ""; display: table; }
	.ui-helper-clearfix:after { clear: both; }
	.ui-helper-clearfix { zoom: 1; }
	.ui-helper-zfix { width: 100%; height: 100%; top: 0; left: 0; position: absolute; opacity: 0; filter:Alpha(Opacity=0); }
	
	
	/* Interaction Cues
	----------------------------------*/
	.ui-state-disabled { cursor: default !important; }
	
	
	/* Icons
	----------------------------------*/
	
	/* states and images */
	.ui-icon { display: block; text-indent: -99999px; overflow: hidden; background-repeat: no-repeat; }
	
	
	/* Misc visuals
	----------------------------------*/
	
	/* Overlays */
	.ui-widget-overlay { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }
	.ui-resizable { position: relative;}
	.ui-resizable-handle { position: absolute;font-size: 0.1px; display: block; }
	.ui-resizable-disabled .ui-resizable-handle, .ui-resizable-autohide .ui-resizable-handle { display: none; }
	.ui-resizable-n { cursor: n-resize; height: 7px; width: 100%; top: -5px; left: 0; }
	.ui-resizable-s { cursor: s-resize; height: 7px; width: 100%; bottom: -5px; left: 0; }
	.ui-resizable-e { cursor: e-resize; width: 7px; right: -5px; top: 0; height: 100%; }
	.ui-resizable-w { cursor: w-resize; width: 7px; left: -5px; top: 0; height: 100%; }
	.ui-resizable-se { cursor: se-resize; width: 12px; height: 12px; right: 1px; bottom: 1px; }
	.ui-resizable-sw { cursor: sw-resize; width: 9px; height: 9px; left: -5px; bottom: -5px; }
	.ui-resizable-nw { cursor: nw-resize; width: 9px; height: 9px; left: -5px; top: -5px; }
	.ui-resizable-ne { cursor: ne-resize; width: 9px; height: 9px; right: -5px; top: -5px;}.ui-selectable-helper { position: absolute; z-index: 100; border:1px dotted black; }
	.ui-accordion .ui-accordion-header { display: block; cursor: pointer; position: relative; margin-top: 2px; padding: .5em .5em .5em .7em; zoom: 1; }
	.ui-accordion .ui-accordion-icons { padding-left: 2.2em; }
	.ui-accordion .ui-accordion-noicons { padding-left: .7em; }
	.ui-accordion .ui-accordion-icons .ui-accordion-icons { padding-left: 2.2em; }
	.ui-accordion .ui-accordion-header .ui-accordion-header-icon { position: absolute; left: .5em; top: 50%; margin-top: -8px; }
	.ui-accordion .ui-accordion-content { padding: 1em 2.2em; border-top: 0; overflow: auto; zoom: 1; }
	.ui-autocomplete { position: absolute; cursor: default; }
	
	/* workarounds */
	* html .ui-autocomplete { width:1px; } /* without this, the menu expands to 100% in IE6 */
	.ui-button { display: inline-block; position: relative; padding: 0; margin-right: .1em; cursor: pointer; text-align: center; zoom: 1; overflow: visible; } /* the overflow property removes extra width in IE */
	.ui-button, .ui-button:link, .ui-button:visited, .ui-button:hover, .ui-button:active { text-decoration: none; }
	.ui-button-icon-only { width: 2.2em; } /* to make room for the icon, a width needs to be set here */
	button.ui-button-icon-only { width: 2.4em; } /* button elements seem to need a little more width */
	.ui-button-icons-only { width: 3.4em; }
	button.ui-button-icons-only { width: 3.7em; }
	
	/*button text element */
	.ui-button .ui-button-text { display: block; line-height: 1.4;  }
	.ui-button-text-only .ui-button-text { padding: .4em 1em; }
	.ui-button-icon-only .ui-button-text, .ui-button-icons-only .ui-button-text { padding: .4em; text-indent: -9999999px; }
	.ui-button-text-icon-primary .ui-button-text, .ui-button-text-icons .ui-button-text { padding: .4em 1em .4em 2.1em; }
	.ui-button-text-icon-secondary .ui-button-text, .ui-button-text-icons .ui-button-text { padding: .4em 2.1em .4em 1em; }
	.ui-button-text-icons .ui-button-text { padding-left: 2.1em; padding-right: 2.1em; }
	/* no icon support for input elements, provide padding by default */
	input.ui-button { padding: .4em 1em; }
	
	/*button icon element(s) */
	.ui-button-icon-only .ui-icon, .ui-button-text-icon-primary .ui-icon, .ui-button-text-icon-secondary .ui-icon, .ui-button-text-icons .ui-icon, .ui-button-icons-only .ui-icon { position: absolute; top: 50%; margin-top: -8px; }
	.ui-button-icon-only .ui-icon { left: 50%; margin-left: -8px; }
	.ui-button-text-icon-primary .ui-button-icon-primary, .ui-button-text-icons .ui-button-icon-primary, .ui-button-icons-only .ui-button-icon-primary { left: .5em; }
	.ui-button-text-icon-secondary .ui-button-icon-secondary, .ui-button-text-icons .ui-button-icon-secondary, .ui-button-icons-only .ui-button-icon-secondary { right: .5em; }
	.ui-button-text-icons .ui-button-icon-secondary, .ui-button-icons-only .ui-button-icon-secondary { right: .5em; }
	
	/*button sets*/
	.ui-buttonset { margin-right: 7px; }
	.ui-buttonset .ui-button { margin-left: 0; margin-right: -.3em; }
	
	/* workarounds */
	button.ui-button::-moz-focus-inner { border: 0; padding: 0; } /* reset extra padding in Firefox */
	.ui-datepicker { width: 17em; padding: .2em .2em 0; display: none; }
	.ui-datepicker .ui-datepicker-header { position:relative; padding:.2em 0; }
	.ui-datepicker .ui-datepicker-prev, .ui-datepicker .ui-datepicker-next { position:absolute; top: 2px; width: 1.8em; height: 1.8em; }
	.ui-datepicker .ui-datepicker-prev-hover, .ui-datepicker .ui-datepicker-next-hover { top: 1px; }
	.ui-datepicker .ui-datepicker-prev { left:2px; }
	.ui-datepicker .ui-datepicker-next { right:2px; }
	.ui-datepicker .ui-datepicker-prev-hover { left:1px; }
	.ui-datepicker .ui-datepicker-next-hover { right:1px; }
	.ui-datepicker .ui-datepicker-prev span, .ui-datepicker .ui-datepicker-next span { display: block; position: absolute; left: 50%; margin-left: -8px; top: 50%; margin-top: -8px;  }
	.ui-datepicker .ui-datepicker-title { margin: 0 2.3em; line-height: 1.8em; text-align: center; }
	.ui-datepicker .ui-datepicker-title select { font-size:1em; margin:1px 0; }
	.ui-datepicker select.ui-datepicker-month-year {width: 100%;}
	.ui-datepicker select.ui-datepicker-month,
	.ui-datepicker select.ui-datepicker-year { width: 49%;}
	.ui-datepicker table {width: 100%; font-size: .9em; border-collapse: collapse; margin:0 0 .4em; }
	.ui-datepicker th { padding: .7em .3em; text-align: center; font-weight: bold; border: 0;  }
	.ui-datepicker td { border: 0; padding: 1px; }
	.ui-datepicker td span, .ui-datepicker td a { display: block; padding: .2em; text-align: right; text-decoration: none; }
	.ui-datepicker .ui-datepicker-buttonpane { background-image: none; margin: .7em 0 0 0; padding:0 .2em; border-left: 0; border-right: 0; border-bottom: 0; }
	.ui-datepicker .ui-datepicker-buttonpane button { float: right; margin: .5em .2em .4em; cursor: pointer; padding: .2em .6em .3em .6em; width:auto; overflow:visible; }
	.ui-datepicker .ui-datepicker-buttonpane button.ui-datepicker-current { float:left; }
	
	/* with multiple calendars */
	.ui-datepicker.ui-datepicker-multi { width:auto; }
	.ui-datepicker-multi .ui-datepicker-group { float:left; }
	.ui-datepicker-multi .ui-datepicker-group table { width:95%; margin:0 auto .4em; }
	.ui-datepicker-multi-2 .ui-datepicker-group { width:50%; }
	.ui-datepicker-multi-3 .ui-datepicker-group { width:33.3%; }
	.ui-datepicker-multi-4 .ui-datepicker-group { width:25%; }
	.ui-datepicker-multi .ui-datepicker-group-last .ui-datepicker-header { border-left-width:0; }
	.ui-datepicker-multi .ui-datepicker-group-middle .ui-datepicker-header { border-left-width:0; }
	.ui-datepicker-multi .ui-datepicker-buttonpane { clear:left; }
	.ui-datepicker-row-break { clear:both; width:100%; font-size:0em; }
	
	/* RTL support */
	.ui-datepicker-rtl { direction: rtl; }
	.ui-datepicker-rtl .ui-datepicker-prev { right: 2px; left: auto; }
	.ui-datepicker-rtl .ui-datepicker-next { left: 2px; right: auto; }
	.ui-datepicker-rtl .ui-datepicker-prev:hover { right: 1px; left: auto; }
	.ui-datepicker-rtl .ui-datepicker-next:hover { left: 1px; right: auto; }
	.ui-datepicker-rtl .ui-datepicker-buttonpane { clear:right; }
	.ui-datepicker-rtl .ui-datepicker-buttonpane button { float: left; }
	.ui-datepicker-rtl .ui-datepicker-buttonpane button.ui-datepicker-current { float:right; }
	.ui-datepicker-rtl .ui-datepicker-group { float:right; }
	.ui-datepicker-rtl .ui-datepicker-group-last .ui-datepicker-header { border-right-width:0; border-left-width:1px; }
	.ui-datepicker-rtl .ui-datepicker-group-middle .ui-datepicker-header { border-right-width:0; border-left-width:1px; }
	
	/* IE6 IFRAME FIX (taken from datepicker 1.5.3 */
	.ui-datepicker-cover {
		position: absolute; /*must have*/
		z-index: -1; /*must have*/
		filter: mask(); /*must have*/
		top: -4px; /*must have*/
		left: -4px; /*must have*/
		width: 200px; /*must have*/
		height: 200px; /*must have*/
	}.ui-dialog { position: absolute; top: 0; left: 0; padding: .2em; width: 300px; overflow: hidden; }
	.ui-dialog .ui-dialog-titlebar { padding: .4em 1em;  height: 16px; position: relative;}
	.ui-dialog .ui-dialog-title { float: left; margin: .1em 16px .1em 0; }
	.ui-dialog .ui-dialog-titlebar-close { position: absolute; right: .3em; top: 50%; width: 19px; margin: -10px 0 0 0; padding: 1px; height: 18px; }
	/*.ui-dialog .ui-dialog-titlebar-close span { display: block; margin: 1px; }*/
	.ui-dialog .ui-dialog-titlebar-close:hover, .ui-dialog .ui-dialog-titlebar-close:focus { padding: 0; }
	.ui-dialog .ui-dialog-content { position: relative; border: 0; padding: .5em 1em; background: none; overflow: auto; zoom: 1; }
	.ui-dialog .ui-dialog-buttonpane { text-align: left; border-width: 1px 0 0 0; background-image: none; margin: .5em 0 0 0; padding: .3em 1em .5em .4em; }
	.ui-dialog .ui-dialog-buttonpane .ui-dialog-buttonset { float: right; }
	.ui-dialog .ui-dialog-buttonpane button { margin: .5em .4em .5em 0; cursor: pointer; }
	.ui-dialog .ui-resizable-se { width: 14px; height: 14px; right: 3px; bottom: 3px; }
	.ui-draggable .ui-dialog-titlebar { cursor: move; }
	.ui-menu { list-style:none; padding: 2px; margin: 0; display:block; outline: none; }
	.ui-menu .ui-menu { margin-top: -3px; position: absolute; }
	.ui-menu .ui-menu-item { margin: 0; padding: 0; zoom: 1; width: 100%; }
	.ui-menu .ui-menu-divider { margin: 5px -2px 5px -2px; height: 0; font-size: 0; line-height: 0; border-width: 1px 0 0 0; }
	.ui-menu .ui-menu-item a { text-decoration: none; display: block; padding: 2px .4em; line-height: 1.5; zoom: 1; font-weight: normal; }
	.ui-menu .ui-menu-item a.ui-state-focus,
	.ui-menu .ui-menu-item a.ui-state-active { font-weight: normal; margin: -1px; }
	
	.ui-menu .ui-state-disabled { font-weight: normal; margin: .4em 0 .2em; line-height: 1.5; }
	.ui-menu .ui-state-disabled a { cursor: default; }
	
	/* icon support */
	.ui-menu-icons { position: relative; }
	.ui-menu-icons .ui-menu-item a { position: relative; padding-left: 2em; }
	
	/* left-aligned */
	.ui-menu .ui-icon { position: absolute; top: .2em; left: .2em; }
	
	/* right-aligned */
	.ui-menu .ui-menu-icon { position: static; float: right; }
	.ui-progressbar { height:2em; text-align: left; overflow: hidden; }
	.ui-progressbar .ui-progressbar-value {margin: -1px; height:100%; }.ui-slider { position: relative; text-align: left; }
	.ui-slider .ui-slider-handle { position: absolute; z-index: 2; width: 1.2em; height: 1.2em; cursor: default; }
	.ui-slider .ui-slider-range { position: absolute; z-index: 1; font-size: .7em; display: block; border: 0; background-position: 0 0; }
	
	.ui-slider-horizontal { height: .8em; }
	.ui-slider-horizontal .ui-slider-handle { top: -.3em; margin-left: -.6em; }
	.ui-slider-horizontal .ui-slider-range { top: 0; height: 100%; }
	.ui-slider-horizontal .ui-slider-range-min { left: 0; }
	.ui-slider-horizontal .ui-slider-range-max { right: 0; }
	
	.ui-slider-vertical { width: .8em; height: 100px; }
	.ui-slider-vertical .ui-slider-handle { left: -.3em; margin-left: 0; margin-bottom: -.6em; }
	.ui-slider-vertical .ui-slider-range { left: 0; width: 100%; }
	.ui-slider-vertical .ui-slider-range-min { bottom: 0; }
	.ui-slider-vertical .ui-slider-range-max { top: 0; }.ui-spinner { position:relative; display: inline-block; overflow: hidden; padding: 0; vertical-align: middle; }
	.ui-spinner-input { border: none; background: none; padding: 0; margin: .2em 0; vertical-align: middle; margin-left: .4em; margin-right: 22px; }
	.ui-spinner-button { width: 16px; height: 50%; font-size: .5em; padding: 0; margin: 0; z-index: 100; text-align: center; position: absolute; cursor: default; display: block; overflow: hidden; right: 0; }
	.ui-spinner a.ui-spinner-button { border-top: none; border-bottom: none; border-right: none; } /* more specificity required here to overide default borders */
	.ui-spinner .ui-icon { position: absolute; margin-top: -8px; top: 50%; left: 0; } /* vertical centre icon */
	.ui-spinner-up { top: 0; }
	.ui-spinner-down { bottom: 0; }
	
	/* TR overrides */
	span.ui-spinner { background: none; }
	.ui-spinner .ui-icon-triangle-1-s {
	  /* need to fix icons sprite */
	  background-position:-65px -16px;
	}
	.ui-tabs { position: relative; padding: .2em; zoom: 1; } /* position: relative prevents IE scroll bug (element with position: relative inside container with overflow: auto appear as "fixed") */
	.ui-tabs .ui-tabs-nav { margin: 0; padding: .2em .2em 0; }
	.ui-tabs .ui-tabs-nav li { list-style: none; float: left; position: relative; top: 0; margin: 1px .2em 0 0; border-bottom: 0; padding: 0; white-space: nowrap; }
	.ui-tabs .ui-tabs-nav li a { float: left; padding: .5em 1em; text-decoration: none; }
	.ui-tabs .ui-tabs-nav li.ui-tabs-active { margin-bottom: -1px; padding-bottom: 1px; }
	.ui-tabs .ui-tabs-nav li.ui-tabs-active a, .ui-tabs .ui-tabs-nav li.ui-state-disabled a, .ui-tabs .ui-tabs-nav li.ui-tabs-loading a { cursor: text; }
	.ui-tabs .ui-tabs-nav li a, .ui-tabs-collapsible .ui-tabs-nav li.ui-tabs-active a { cursor: pointer; } /* first selector in group seems obsolete, but required to overcome bug in Opera applying cursor: text overall if defined elsewhere... */
	.ui-tabs .ui-tabs-panel { display: block; border-width: 0; padding: 1em 1.4em; background: none; }
	.ui-tooltip {
	  padding:8px;
	  position:absolute;
	  z-index:9999;
	  -o-box-shadow: 0 0 5px #aaa;
	  -moz-box-shadow: 0 0 5px #aaa;
	  -webkit-box-shadow: 0 0 5px #aaa;
	  box-shadow: 0 0 5px #aaa;
	}
	/* Fades and background-images don't work well together in IE6, drop the image */
	* html .ui-tooltip {
	  background-image: none;
	}
	body .ui-tooltip { border-width:2px; }
	
	/* Component containers
	----------------------------------*/
	.ui-widget { font-size: 1.1em; }
	.ui-widget .ui-widget { font-size: 1em; }
	.ui-widget input, .ui-widget select, .ui-widget textarea, .ui-widget button { font-size: 1em; }
	.ui-widget-content { border: 1px solid #ccc; background: #fcfdfd url(../images/ui-bg_inset-hard_100_fcfdfd_1x100.png) 50% bottom repeat-x; color: #222222; }
	.ui-widget-content a { color: #222222; }
	.ui-widget-header { border: 1px solid #5d819d; background: #5d819d url(../images/main-nav.gif) top left repeat-x; color: #ffffff; font-weight: bold; }
	.ui-widget-header a { color: #ffffff; }
	
	/* Interaction states
	----------------------------------*/
	.ui-state-default, .ui-widget-content .ui-state-default, .ui-widget-header .ui-state-default { border: 1px solid #c5dbec; background: #dfeffc url(../images/ui-bg_glass_85_dfeffc_1x400.png) 50% 50% repeat-x; font-weight: bold; color: #2e6e9e; }
	.ui-state-default a, .ui-state-default a:link, .ui-state-default a:visited { color: #2e6e9e; text-decoration: none; }
	.ui-state-hover, .ui-widget-content .ui-state-hover, .ui-widget-header .ui-state-hover, .ui-state-focus, .ui-widget-content .ui-state-focus, .ui-widget-header .ui-state-focus { border: 1px solid #79b7e7; background: #d0e5f5 url(../images/ui-bg_glass_75_d0e5f5_1x400.png) 50% 50% repeat-x; font-weight: bold; color: #1d5987; }
	.ui-state-hover a, .ui-state-hover a:hover { color: #1d5987; text-decoration: none; }
	.ui-state-active, .ui-widget-content .ui-state-active, .ui-widget-header .ui-state-active { border: 1px solid #79b7e7; background: #f5f8f9 url(../images/ui-bg_inset-hard_100_f5f8f9_1x100.png) 50% 50% repeat-x; font-weight: bold; color: #e17009; }
	.ui-state-active a, .ui-state-active a:link, .ui-state-active a:visited { color: #e17009; text-decoration: none; }
	
	/* Interaction Cues
	----------------------------------*/
	.ui-state-highlight, .ui-widget-content .ui-state-highlight, .ui-widget-header .ui-state-highlight  {border: 1px solid #fad42e; background: #fbec88 url(../images/ui-bg_flat_55_fbec88_40x100.png) 50% 50% repeat-x; color: #363636; }
	.ui-state-highlight a, .ui-widget-content .ui-state-highlight a,.ui-widget-header .ui-state-highlight a { color: #363636; }
	.ui-state-error, .ui-widget-content .ui-state-error, .ui-widget-header .ui-state-error {border: 1px solid #cd0a0a; background: #fef1ec url(../images/ui-bg_glass_95_fef1ec_1x400.png) 50% 50% repeat-x; color: #cd0a0a; }
	.ui-state-error a, .ui-widget-content .ui-state-error a, .ui-widget-header .ui-state-error a { color: #cd0a0a; }
	.ui-state-error-text, .ui-widget-content .ui-state-error-text, .ui-widget-header .ui-state-error-text { color: #cd0a0a; }
	.ui-priority-primary, .ui-widget-content .ui-priority-primary, .ui-widget-header .ui-priority-primary { font-weight: bold; }
	.ui-priority-secondary, .ui-widget-content .ui-priority-secondary,  .ui-widget-header .ui-priority-secondary { opacity: .7; filter:Alpha(Opacity=70); font-weight: normal; }
	.ui-state-disabled, .ui-widget-content .ui-state-disabled, .ui-widget-header .ui-state-disabled { opacity: .35; filter:Alpha(Opacity=35); background-image: none; }
	
	/* Icons
	----------------------------------*/
	
	/* states and images */
	.ui-icon { width: 16px; height: 16px; background-image: url(../images/ui-icons_469bdd_256x240.png); }
	.ui-widget-content .ui-icon {background-image: url(../images/ui-icons_469bdd_256x240.png); }
	.ui-widget-header .ui-icon {background-image: url(../images/ui-icons_d8e7f3_256x240.png); }
	.ui-state-default .ui-icon { background-image: url(../images/ui-icons_6da8d5_256x240.png); }
	.ui-state-hover .ui-icon, .ui-state-focus .ui-icon {background-image: url(../images/ui-icons_217bc0_256x240.png); }
	.ui-state-active .ui-icon {background-image: url(../images/ui-icons_f9bd01_256x240.png); }
	.ui-state-highlight .ui-icon {background-image: url(../images/ui-icons_2e83ff_256x240.png); }
	.ui-state-error .ui-icon, .ui-state-error-text .ui-icon {background-image: url(../images/ui-icons_cd0a0a_256x240.png); }
	
	/* positioning */
	.ui-icon-carat-1-n { background-position: 0 0; }
	.ui-icon-carat-1-ne { background-position: -16px 0; }
	.ui-icon-carat-1-e { background-position: -32px 0; }
	.ui-icon-carat-1-se { background-position: -48px 0; }
	.ui-icon-carat-1-s { background-position: -64px 0; }
	.ui-icon-carat-1-sw { background-position: -80px 0; }
	.ui-icon-carat-1-w { background-position: -96px 0; }
	.ui-icon-carat-1-nw { background-position: -112px 0; }
	.ui-icon-carat-2-n-s { background-position: -128px 0; }
	.ui-icon-carat-2-e-w { background-position: -144px 0; }
	.ui-icon-triangle-1-n { background-position: 0 -16px; }
	.ui-icon-triangle-1-ne { background-position: -16px -16px; }
	.ui-icon-triangle-1-e { background-position: -32px -16px; }
	.ui-icon-triangle-1-se { background-position: -48px -16px; }
	.ui-icon-triangle-1-s { background-position: -64px -16px; }
	.ui-icon-triangle-1-sw { background-position: -80px -16px; }
	.ui-icon-triangle-1-w { background-position: -96px -16px; }
	.ui-icon-triangle-1-nw { background-position: -112px -16px; }
	.ui-icon-triangle-2-n-s { background-position: -128px -16px; }
	.ui-icon-triangle-2-e-w { background-position: -144px -16px; }
	.ui-icon-arrow-1-n { background-position: 0 -32px; }
	.ui-icon-arrow-1-ne { background-position: -16px -32px; }
	.ui-icon-arrow-1-e { background-position: -32px -32px; }
	.ui-icon-arrow-1-se { background-position: -48px -32px; }
	.ui-icon-arrow-1-s { background-position: -64px -32px; }
	.ui-icon-arrow-1-sw { background-position: -80px -32px; }
	.ui-icon-arrow-1-w { background-position: -96px -32px; }
	.ui-icon-arrow-1-nw { background-position: -112px -32px; }
	.ui-icon-arrow-2-n-s { background-position: -128px -32px; }
	.ui-icon-arrow-2-ne-sw { background-position: -144px -32px; }
	.ui-icon-arrow-2-e-w { background-position: -160px -32px; }
	.ui-icon-arrow-2-se-nw { background-position: -176px -32px; }
	.ui-icon-arrowstop-1-n { background-position: -192px -32px; }
	.ui-icon-arrowstop-1-e { background-position: -208px -32px; }
	.ui-icon-arrowstop-1-s { background-position: -224px -32px; }
	.ui-icon-arrowstop-1-w { background-position: -240px -32px; }
	.ui-icon-arrowthick-1-n { background-position: 0 -48px; }
	.ui-icon-arrowthick-1-ne { background-position: -16px -48px; }
	.ui-icon-arrowthick-1-e { background-position: -32px -48px; }
	.ui-icon-arrowthick-1-se { background-position: -48px -48px; }
	.ui-icon-arrowthick-1-s { background-position: -64px -48px; }
	.ui-icon-arrowthick-1-sw { background-position: -80px -48px; }
	.ui-icon-arrowthick-1-w { background-position: -96px -48px; }
	.ui-icon-arrowthick-1-nw { background-position: -112px -48px; }
	.ui-icon-arrowthick-2-n-s { background-position: -128px -48px; }
	.ui-icon-arrowthick-2-ne-sw { background-position: -144px -48px; }
	.ui-icon-arrowthick-2-e-w { background-position: -160px -48px; }
	.ui-icon-arrowthick-2-se-nw { background-position: -176px -48px; }
	.ui-icon-arrowthickstop-1-n { background-position: -192px -48px; }
	.ui-icon-arrowthickstop-1-e { background-position: -208px -48px; }
	.ui-icon-arrowthickstop-1-s { background-position: -224px -48px; }
	.ui-icon-arrowthickstop-1-w { background-position: -240px -48px; }
	.ui-icon-arrowreturnthick-1-w { background-position: 0 -64px; }
	.ui-icon-arrowreturnthick-1-n { background-position: -16px -64px; }
	.ui-icon-arrowreturnthick-1-e { background-position: -32px -64px; }
	.ui-icon-arrowreturnthick-1-s { background-position: -48px -64px; }
	.ui-icon-arrowreturn-1-w { background-position: -64px -64px; }
	.ui-icon-arrowreturn-1-n { background-position: -80px -64px; }
	.ui-icon-arrowreturn-1-e { background-position: -96px -64px; }
	.ui-icon-arrowreturn-1-s { background-position: -112px -64px; }
	.ui-icon-arrowrefresh-1-w { background-position: -128px -64px; }
	.ui-icon-arrowrefresh-1-n { background-position: -144px -64px; }
	.ui-icon-arrowrefresh-1-e { background-position: -160px -64px; }
	.ui-icon-arrowrefresh-1-s { background-position: -176px -64px; }
	.ui-icon-arrow-4 { background-position: 0 -80px; }
	.ui-icon-arrow-4-diag { background-position: -16px -80px; }
	.ui-icon-extlink { background-position: -32px -80px; }
	.ui-icon-newwin { background-position: -48px -80px; }
	.ui-icon-refresh { background-position: -64px -80px; }
	.ui-icon-shuffle { background-position: -80px -80px; }
	.ui-icon-transfer-e-w { background-position: -96px -80px; }
	.ui-icon-transferthick-e-w { background-position: -112px -80px; }
	.ui-icon-folder-collapsed { background-position: 0 -96px; }
	.ui-icon-folder-open { background-position: -16px -96px; }
	.ui-icon-document { background-position: -32px -96px; }
	.ui-icon-document-b { background-position: -48px -96px; }
	.ui-icon-note { background-position: -64px -96px; }
	.ui-icon-mail-closed { background-position: -80px -96px; }
	.ui-icon-mail-open { background-position: -96px -96px; }
	.ui-icon-suitcase { background-position: -112px -96px; }
	.ui-icon-comment { background-position: -128px -96px; }
	.ui-icon-person { background-position: -144px -96px; }
	.ui-icon-print { background-position: -160px -96px; }
	.ui-icon-trash { background-position: -176px -96px; }
	.ui-icon-locked { background-position: -192px -96px; }
	.ui-icon-unlocked { background-position: -208px -96px; }
	.ui-icon-bookmark { background-position: -224px -96px; }
	.ui-icon-tag { background-position: -240px -96px; }
	.ui-icon-home { background-position: 0 -112px; }
	.ui-icon-flag { background-position: -16px -112px; }
	.ui-icon-calendar { background-position: -32px -112px; }
	.ui-icon-cart { background-position: -48px -112px; }
	.ui-icon-pencil { background-position: -64px -112px; }
	.ui-icon-clock { background-position: -80px -112px; }
	.ui-icon-disk { background-position: -96px -112px; }
	.ui-icon-calculator { background-position: -112px -112px; }
	.ui-icon-zoomin { background-position: -128px -112px; }
	.ui-icon-zoomout { background-position: -144px -112px; }
	.ui-icon-search { background-position: -160px -112px; }
	.ui-icon-wrench { background-position: -176px -112px; }
	.ui-icon-gear { background-position: -192px -112px; }
	.ui-icon-heart { background-position: -208px -112px; }
	.ui-icon-star { background-position: -224px -112px; }
	.ui-icon-link { background-position: -240px -112px; }
	.ui-icon-cancel { background-position: 0 -128px; }
	.ui-icon-plus { background-position: -16px -128px; }
	.ui-icon-plusthick { background-position: -32px -128px; }
	.ui-icon-minus { background-position: -48px -128px; }
	.ui-icon-minusthick { background-position: -64px -128px; }
	.ui-icon-close { background-position: -80px -128px; }
	.ui-icon-closethick { background-position: -96px -128px; }
	.ui-icon-key { background-position: -112px -128px; }
	.ui-icon-lightbulb { background-position: -128px -128px; }
	.ui-icon-scissors { background-position: -144px -128px; }
	.ui-icon-clipboard { background-position: -160px -128px; }
	.ui-icon-copy { background-position: -176px -128px; }
	.ui-icon-contact { background-position: -192px -128px; }
	.ui-icon-image { background-position: -208px -128px; }
	.ui-icon-video { background-position: -224px -128px; }
	.ui-icon-script { background-position: -240px -128px; }
	.ui-icon-alert { background-position: 0 -144px; }
	.ui-icon-info { background-position: -16px -144px; }
	.ui-icon-notice { background-position: -32px -144px; }
	.ui-icon-help { background-position: -48px -144px; }
	.ui-icon-check { background-position: -64px -144px; }
	.ui-icon-bullet { background-position: -80px -144px; }
	.ui-icon-radio-on { background-position: -96px -144px; }
	.ui-icon-radio-off { background-position: -112px -144px; }
	.ui-icon-pin-w { background-position: -128px -144px; }
	.ui-icon-pin-s { background-position: -144px -144px; }
	.ui-icon-play { background-position: 0 -160px; }
	.ui-icon-pause { background-position: -16px -160px; }
	.ui-icon-seek-next { background-position: -32px -160px; }
	.ui-icon-seek-prev { background-position: -48px -160px; }
	.ui-icon-seek-end { background-position: -64px -160px; }
	.ui-icon-seek-start { background-position: -80px -160px; }
	/* ui-icon-seek-first is deprecated, use ui-icon-seek-start instead */
	.ui-icon-seek-first { background-position: -80px -160px; }
	.ui-icon-stop { background-position: -96px -160px; }
	.ui-icon-eject { background-position: -112px -160px; }
	.ui-icon-volume-off { background-position: -128px -160px; }
	.ui-icon-volume-on { background-position: -144px -160px; }
	.ui-icon-power { background-position: 0 -176px; }
	.ui-icon-signal-diag { background-position: -16px -176px; }
	.ui-icon-signal { background-position: -32px -176px; }
	.ui-icon-battery-0 { background-position: -48px -176px; }
	.ui-icon-battery-1 { background-position: -64px -176px; }
	.ui-icon-battery-2 { background-position: -80px -176px; }
	.ui-icon-battery-3 { background-position: -96px -176px; }
	.ui-icon-circle-plus { background-position: 0 -192px; }
	.ui-icon-circle-minus { background-position: -16px -192px; }
	.ui-icon-circle-close { background-position: -32px -192px; }
	.ui-icon-circle-triangle-e { background-position: -48px -192px; }
	.ui-icon-circle-triangle-s { background-position: -64px -192px; }
	.ui-icon-circle-triangle-w { background-position: -80px -192px; }
	.ui-icon-circle-triangle-n { background-position: -96px -192px; }
	.ui-icon-circle-arrow-e { background-position: -112px -192px; }
	.ui-icon-circle-arrow-s { background-position: -128px -192px; }
	.ui-icon-circle-arrow-w { background-position: -144px -192px; }
	.ui-icon-circle-arrow-n { background-position: -160px -192px; }
	.ui-icon-circle-zoomin { background-position: -176px -192px; }
	.ui-icon-circle-zoomout { background-position: -192px -192px; }
	.ui-icon-circle-check { background-position: -208px -192px; }
	.ui-icon-circlesmall-plus { background-position: 0 -208px; }
	.ui-icon-circlesmall-minus { background-position: -16px -208px; }
	.ui-icon-circlesmall-close { background-position: -32px -208px; }
	.ui-icon-squaresmall-plus { background-position: -48px -208px; }
	.ui-icon-squaresmall-minus { background-position: -64px -208px; }
	.ui-icon-squaresmall-close { background-position: -80px -208px; }
	.ui-icon-grip-dotted-vertical { background-position: 0 -224px; }
	.ui-icon-grip-dotted-horizontal { background-position: -16px -224px; }
	.ui-icon-grip-solid-vertical { background-position: -32px -224px; }
	.ui-icon-grip-solid-horizontal { background-position: -48px -224px; }
	.ui-icon-gripsmall-diagonal-se { background-position: -64px -224px; }
	.ui-icon-grip-diagonal-se { background-position: -80px -224px; }
	
	
	/* Misc visuals
	----------------------------------*/
	
	/* Corner radius */
	.ui-corner-all, .ui-corner-top, .ui-corner-left, .ui-corner-tl { -moz-border-radius-topleft: 5px; -webkit-border-top-left-radius: 5px; -khtml-border-top-left-radius: 5px; border-top-left-radius: 5px; }
	.ui-corner-all, .ui-corner-top, .ui-corner-right, .ui-corner-tr { -moz-border-radius-topright: 5px; -webkit-border-top-right-radius: 5px; -khtml-border-top-right-radius: 5px; border-top-right-radius: 5px; }
	.ui-corner-all, .ui-corner-bottom, .ui-corner-left, .ui-corner-bl { -moz-border-radius-bottomleft: 5px; -webkit-border-bottom-left-radius: 5px; -khtml-border-bottom-left-radius: 5px; border-bottom-left-radius: 5px; }
	.ui-corner-all, .ui-corner-bottom, .ui-corner-right, .ui-corner-br { -moz-border-radius-bottomright: 5px; -webkit-border-bottom-right-radius: 5px; -khtml-border-bottom-right-radius: 5px; border-bottom-right-radius: 5px; }
	
	/* Overlays */
	.ui-widget-overlay { background: #aaaaaa url(../images/ui-bg_flat_0_aaaaaa_40x100.png) 50% 50% repeat-x; opacity: .3;filter:Alpha(Opacity=30); }
	.ui-widget-shadow { margin: -8px 0 0 -8px; padding: 8px; background: #aaaaaa url(../images/ui-bg_flat_0_aaaaaa_40x100.png) 50% 50% repeat-x; opacity: .3;filter:Alpha(Opacity=30); -moz-border-radius: 8px; -khtml-border-radius: 8px; -webkit-border-radius: 8px; border-radius: 8px; }
	
	/* Calendar Button and Image */
	.view-calendar button {
	background: url(../images/cal.gif) no-repeat !important;
	display: inline-block;
	border: none;
	vertical-align: middle;
	/*wt;begin/end*/
	margin: 2px 2px 2px 2px;
	height: 18px;
	width: 18px;
	}
	
	/* css for timepicker */
	.ui-timepicker-div .ui-widget-header { margin-bottom: 8px; }
	.ui-timepicker-div dl { text-align: left; }
	.ui-timepicker-div dl dt { height: 25px; margin-bottom: -25px; }
	.ui-timepicker-div dl dd { margin: 0 10px 10px 65px; }
	.ui-timepicker-div td { font-size: 90%; }
	.ui-tpicker-grid-label { background: none; border: none; margin: 0; padding: 0; }









	
/* 

	This is fnp-backend.css

*/






/*==========================================================  */
/* Slider */
.slick-slider
{
    position: relative;

    display: block;

    -moz-box-sizing: border-box;
         box-sizing: border-box;

    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;

    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
        touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}

.slick-list
{
    position: relative;

    display: block;
    overflow: hidden;

    margin: 0;
    padding: 0;
}
.slick-list:focus
{
    outline: none;
}
.slick-list.dragging
{
    cursor: pointer;
    cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list
{
    -webkit-transform: translate3d(0, 0, 0);
       -moz-transform: translate3d(0, 0, 0);
        -ms-transform: translate3d(0, 0, 0);
         -o-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
}

.slick-track
{
    position: relative;
    top: 0;
    left: 0;

    display: block;
}
.slick-track:before,
.slick-track:after
{
    display: table;

    content: '';
}
.slick-track:after
{
    clear: both;
}
.slick-loading .slick-track
{
    visibility: hidden;
}

.slick-slide
{
    display: none;
    float: left;
    height: 100%;
    min-height: 1px;
}
[dir='rtl'] .slick-slide
{
    float: right;
}
.slick-slide img
{
    display: block;
}
.slick-slide.slick-loading img
{
    display: none;
}
.slick-slide.dragging img
{
    pointer-events: none;
}
.slick-initialized .slick-slide
{
    display: block;
}
.slick-loading .slick-slide
{
    visibility: hidden;
}
.slick-vertical .slick-slide
{
    display: block;

    height: auto;

    border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
    display: none;
}

/*=======================================================  */
.capitalize{
	text-transform: capitalize;
}
table.orderLog tr{
	line-height:2;
}
.col150width{
	max-width:150px;
	word-wrap:break-word;
}
.col300width{
	max-width:300px;
	word-wrap:break-word;
	
}
.yellowBg{
	background-color: #FFFF00;
}
.disableOrder{
    display: none;
}
.hidden, input[type="submit"].hidden,input[type="button"].hidden,input[type="text"].hidden{
	display: none;
}
.screenlet-title-bar ul a.buttontext:hover,.screenlet-title-bar ul a:hover{
	color: #000000;
}
.error{
	color:#FF0000;
}
.required.error, .errorbg, .basic-table .alternate-row.errorbg{
	background-color: #FF0000;
	color:#000000;
}
.forceclear{
	clear:both;
}
.sequencecontainer .sequenceImg{
	width:150px;
	height:150px;
}
.sequencecontainer .productblock{
	float:left;
	width:200px;
	height:280px;
	cursor: move;
}
.sequencecontainer .productblock .insideProductblock{
	height:50px;
	overflow: hidden;
}
#app-navigation ul .selected,.tab-bar ul .selected{
	background-color: #990099;
	/* background-color:#5C809C; */
}
#app-navigation ul .selected a,#app-navigation ul .selected a:hover,.tab-bar ul .selected a,.tab-bar ul .selected a:hover{
	color: #ffffff;
	font-weight: bold;
	text-shadow: 2px 2px #000;
}
table.subtable .collapsed{
	visibility: visible;
} 

table.basic-table .collapsed {
    visibility: visible; 
}

.basic-table tr td {
    padding: 0.8em 0.1em;
    position: relative;
}
.basic-table tr td #reasonmsg{
	top: -9px;
}
.basic-table tr td  select[name="reason"]{
	max-width: 200px;
}
.in-sla {
    border-radius: 25px;
    background: rgb(196, 234, 154);
    padding: 20px; 
    width: 200px;
    height: 40px;
    display: inline-block;
}
.out-sla {
    border-radius: 25px;
    background: rgba(241, 37, 4, 0.42);
    padding: 20px; 
    width: 200px;
    height: 40px;
    display: inline-block;
}
.orderDetailsStyle .fieldgroup-title-bar {
	width: 80%;	
}
.orderDetailsStyle .fieldgroup-body {
	width: 89%;	
}
.productDetailsStyle .fieldgroup-title-bar {
	width: 74%;	
}
.discountCouponDialog, .displaynone,.courierShipmentExists .orderActions{
	display:none;
}
.pull-right {
	float: right;
}
.pull-left {
	float: left;

}
.show-dialog{
	display:none;

}
.StrikContent {
	text-decoration: line-through;
}
.orderItemTable {
	width: 100%;
}
.orderItemTable td {
	border: 1px solid black;
	padding-top: 5px;
	padding-bottom: 5px;
	vertical-align :middle;
}
.orderItemTable th {
	text-align: center;
	padding-top: 5px;
	padding-bottom: 5px;
}
.textAlignRight {
	text-align: right;
}
.textAlignLeft {
	text-align: left;
}
.textAlignCenter {
	text-align: center;
}
.orderItemTable .borderNone td{
	border:none;
}
.orderLog td {
	text-align: center;
	outline: 1px solid;
}
.orderLog th {
	text-align: center;
	outline: 1px solid;
	padding: 8px 0px 8px 0px;
}

.makeTextColorRed {
	color: #FF0000;
}

.makeTextColorGreen {
	color: #006000;
}
.supplilerReallocation {
	display: inline-block
}
.OrderLists a{
	padding: 2px;
	margin: 2px;
}

.OrderLists .selectedList,.OrderLists a.selectedList:visited{
	background-color: #5C809C;
	color: #FFF;
	display: inline-block;	
}
.changeShipmentDialog{
	width: 25% !important;	
}
.form-row{
	padding-bottom : 10px;
}
.orderheaderview select{
	width:200px;
}
.orderheaderview  td div,.auditHistory td{
	overflow-wrap: break-all;
	word-wrap: break-all;
	-ms-word-break: break-all;
	word-break: break-all;
}
.appendOrderItem .field-lookup a {
	vertical-align:middle;
}
.supplilerReallocation .makeBgColorColorGreen{
	background: #39b97b;
}
.order-items .makeBgColorColorRed {
	background:#ef516e none; 
}
.ordersearchoptions li{
	margin:5px 0;
}
.paymentTable {
	border-collapse:collapse;
    width:100%;
}
.paymentTable td{
	 border: 1px solid #000;
	 text-align: center;
	 padding-top: 5px;
	padding-bottom: 5px;
}
.paymentTable th{
	 border: 1px solid #000;
	 text-align: center;
	 color: #ffffff;
	padding-top: 8px;
	padding-bottom: 8px;
}
.makeHeaderBgBlue{
	 background-color:#507090;
}

.changeShipmentMethodErrorMsg{
	color: #ff0000;
}
.lookupimg{
	width:30px;
	height:30px;
	vertical-align: middle;
}
.require{
   color:#F00;
}
.countryCode {
	width:70px;
}
.phoneNumber {
	width:185px;
}
.subnav{
	height: 3.5em;
}
.subnav ul{
	margin-bottom: 5px;
}
.subnav ul li {
	float: left;
	vertical-align: center;
	padding: 5px;
}
.productQualityCheck{
	width : 100%;
	height:100%;
	min-height:167px;
}
.qualityCheckImages-slideable{
    width: 200px;
    margin-bottom: 0px;
}
.qualityCheckImages{
	width: 100%;

    /* Firefox */
    display:-moz-box;
    -moz-box-pack:center;
    -moz-box-align:center;

    /* Safari and Chrome */
    display:-webkit-box;
    -webkit-box-pack:center;
    -webkit-box-align:center;

    /* W3C */
    display:flex;
    box-pack:center;
    box-align:center;
}
#prevArrow {
    background: url('../images/left-arrow.png') no-repeat;
    width: 24px;
    height: 28px;
    left: -16px;
    top: 111px;
    position: relative;
}

#nextArrow {
    background: url('../images/arrow.png') no-repeat;
    width: 24px;
    height: 28px;
    left: 216px;
    bottom: 116px;
    position: relative;
}
.qualityCheckImages-slideable{
	width:225px;
	/* height:200px; */
}
.shipment{
	width:50px;
}
.shippingOption,.holdSingleSO,.weightOption,.shippingStatusOption,.dispatchOption,.subOrderIds,.releaseSingleSO,.reAllocationLink,.allocationLink,.packorders,.supplierName,.currentAllocationStatus,.allocationLinkTitle,.acceptedTime{
	display:none;
}

.TO_BE_SHIPPED .subOrderIds,.HOLD .weightOption,.HOLD .packorders,.SHIPMENT_INPUT .packorders,.SHIPMENT_PACKED .weightOption,.SHIPMENT_PACKED .packorders,.SHIPMENT_PICKED .shippingStatusOption,.SHIPMENT_PICKED .dispatchOption,.SUPPLIER_ALLOCATED .reAllocationLink,.SUPPLIER_UNALLOCATED .allocationLink,.SUPPLIER_ALLOCATED .supplierName,.SUPPLIER_ACCEPTED .supplierName,.SHIPMENT_DELIVERED .supplierName,.SUPPLIER_UNALLOCATED .allocationLinkTitle,.SHIPMENT_DLVRY_ATMPT .supplierName,.SHIPMENT_DLVRY_ATMPT .acceptedTime{
	display:table-cell;
}

#CourierOrdersList td,#OrdersList td{
	word-wrap: break-word;
}
#CourierOrdersList select,#OrdersList select{
	width:120px;
}
#barcodeinput,#barcodeinputselected{
	display:inline-block;
	width:49.5%;
	vertical-align:top;
}
#barcodeinputselected{
	background:none;
	border:none;
	border: 1px solid #ccc;
}
.segment{
	width: 183px;
    text-align: center;
}
.orderSearchDate{
	width:200px;
}
.salesChannelError{
	display : none;
	color: #ef2929;
}
.allocationLink{
    white-space: nowrap;
}
.selection{
    width: 2ch;
}
.suborderid{
    width: 8ch;
}
.updatestatus{
    width: 2ch;
}
#AllocationDialogMulti .form-row{
    text-align:center;
}
#allocatemultimsg>img{
    vertical-align: middle;
}
div#AllocationDialogMulti table td {
    padding: 5px;
}
.ui-dialog{
	width :auto;
}
.blockkind,.blockkind li{
    list-style: inside disc;
    margin-bottom: 0.25em;
}
.blocktitle{
    margin-bottom: 0.5em;
}
.blockdata,.newentry{
    border-collapse:collapse;
    min-width:60%;
    margin-top: 15px;
}
.blockdata td,.blockdata th{
    border: 1px solid #ccc;
    vertical-align: middle;
    padding: 0.5em;
    text-align: center;
    max-width: 165px;
}
.newentry td,.newentry th{
    border: 1px solid #ccc;
    vertical-align: middle;
    padding: 0.5em;
    text-align: center;
    max-width: 215px;
}
td.blockRuleName{
	max-width: 200px;
}
td.timeslotdiv{
	max-width: 160px;
}
.blockdata img{
    width:32px;
}
.blockvalue{
    color: #000088;
}
.dialogplaceholder{
    display:none;
}
.choicevalue{
    font-weight:normal;
}
.choicevalue>span{
    margin-left: 5px;
    color:#f00;
    cursor: pointer;
}
.choicevalue>span:hover{
    color:#f88;
}
.choicevalue>span:after{
    content: ", ";
}
.choicevalue:last-child>span:after{
    content: "";
}
.disable_a_href{
    pointer-events: none;
    color:#F9924B;
}

#DLG_BB_GEO{
	height: 150px !important;
	overflow : hidden;
}

#DLG_BB_GEO .field-lookup a, #DLG_BB_DATE .field-lookup a, #DLG_BB_SHIPPING_METHOD .field-lookup a, #DLG_BB_TIMESLOT .field-lookup a{
	display: none;
}
.btnBlock{
	margin-top: 8px;
	margin-bottom: 8px;
}
/*Karan's CSS*/
.textAlignLeft.total {
	color: #0f9d58;
	font-size: 14px;
	font-weight: bold;
}

.textAlignLeft.paid {
	color: #0f639d;
	font-size: 14px;
	font-weight: bold;
}

.textAlignLeft.pending, .pending {
	color: #9d0f0f;
	font-size: 14px;
	font-weight: bold;
}

.textAlignLeft.email, .textAlignLeft.phone, span.phone {
	font-size: 13px;
	font-weight: bold;
}

span.recipient-name, span.sender-name, span.email {
	font-size: 13px;
	font-weight: bold;
}
span.date-Of-delivery {
	font-size: 12px;
	font-weight: bold;
}
span.instruction {
	color: #9d0f0f;
	font-size: 12px;
	font-weight: bold;
}
/*Karan CSS End*/
#OrdersList td.EDD, #OrdersList td.delslot, #OrdersList td.pincode, #CourierOrdersList td.EDD, #CourierOrdersList td.delslot, #CourierOrdersList td.pincode{
	width: 6em;
}
#OrdersList td.orderdate, #CourierOrdersList td.orderdate {
	width: 9em;
}
#OrdersList td.wp, #OrdersList td.pp, #CourierOrdersList td.wp, #CourierOrdersList td.pp {
	max-width: 4.5em;
}
#OrdersList td.source, #CourierOrdersList td.source{
	max-width: 14em;
	white-space: nowrap;
}
#OrdersList td.acceptedTime, #CourierOrdersList td.acceptedTime{
	width: 9em;
}
#CourierOrdersList td.weightOption, #CourierOrdersList td.packorders{
	width: 4em;
}
.moved{
	background-color: #ffffcc;
}
.sequencecontainer{
	max-width:1200px;
}
span.giftMessage {
	margin-left: 70px;
}
.backendOrderRating{
	color:#F9FE0B;
	font-size:20px;
}
.recipientsign{
	width: 215px;
}
#OrdersList .supplierdropdown{
	width: 110px;
	
}
 #OrdersList td{
 	max-width: 120px !important;
 }
 
 .checkboxforsuppliers,.nonallocaatedcheckbox {
 	float:right;
 	margin-right: 20px;
 }
 
 .deltaImport{
    margin-top: 0px;
    margin-bottom: 0px;
    height: 182px;
    width: 325px;
 }
.videoicon {
    background: url(/assets/images/video_normal.png) no-repeat;
    background-size: 37px 37px;
    z-index: 1;
    display: block;
    width: 35px;
    height: 35px;
    text-indent: -9999px;
    margin-left: 218px;
}
.videoicon:hover{
	background: url(/assets/images/video_hover.png) no-repeat;
    background-size: 37px 37px;
    z-index: 1;
    display: block;
    width: 35px;
    height: 35px;
    text-indent: -9999px;
    margin-left: 218px;

}
.close-modal {
    color: #AAAAAA !important;
    cursor: pointer;
    font-size: 1.6rem;
    font-weight: 300;
    position: absolute;
    top: -0.4rem;
    right: 0.1rem;
    z-index: 99;
}
.videodiolog{
	padding: 25px 0px 0px 0px!important;
	background: #212f21 !important;
}
.close-modal .tooltiptext{
	visibility: hidden;
    width: 45px;
    background-color: #f5f5f5;
    color: #222222;
    text-align: center;
    padding: 4px 0;
    position: absolute;
    z-index: 1;
    margin-left: -60px;
    opacity: 0;
    transition: opacity 1s;
    font-size: small;
    top: 22px;
    right: -5px;
}
#vendorvideo{
	overflow: hidden;
}
.close-modal:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
}
.pendingamt {
	display: inline;
	padding-left: 14%;
    padding-right: 3%;
}
.textAlignLeft.donation {
    color: #FFA500;
    font-size: 14px;
    font-weight: bold;
}
a.hideclassnow, select.hideclassnow, span.hideclassnow{
	display: none;
}
.disableAction{
	display: none !important;
}
.refImgDialog {
 	display: none;
 	text-align: center;
 }
 .refImage {
 	width:100%;
 	height:100%;
 	min-height: 270px;
 	min-width:270px;
 }
 


 .datePickPC{
	height: 1px; width: 1px; border: none; background-color: transparent;
 }
 .ordersSideBg{
	border: 1px inset;
	display: inline-block;
	padding: 0.2em;
	margin-left: 0.5em;
	background-color: #ffffcc;
	margin-bottom: 0.2em;
 }
 .hasDatepicker{height: 1px; width: 1px; border: none; background-color: transparent;}
