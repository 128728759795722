.bulk-block {
  table {
    margin-top: 10px;
    border-collapse: collapse;
    text-align: center;

    td,
    th {
      border: 1px solid #ccc;
      padding: 10px;
      max-width: 215px;
      text-align: center;
      vertical-align: middle;
    }
  }
}

.btn-bulk {
  display: inline-block;
  background: linear-gradient(top center, #fff 0%, #ddd 100%);
  border: 1px solid;
  border-color: #ddd #bbb #999;
  cursor: pointer;
  color: #333;
  text-transform: uppercase;
}

.bulk-actions {
  margin-top: 10px;
  display: block;

  button:not(:last-child) {
    margin-right: 10px;
  }
}
// #ddecf7
// #79b7e7
.custom-selection {
  display: none;
  width: 100%;
  // padding: 4px 0px;
  border: 1px solid #ccc;

  &__select {
    background: #ddecf7;
    border: 1px solid #79b7e7;
    border-radius: 4px;
    padding: 4px 0px;
    cursor: pointer;
  }
}

.dis-block {
  display: block;
}

.block-bar {
  display: table;
  margin: 0.5em 0.5em 0 0.5em;
}

.block-bar ul {
  display: inline;
}

.block-bar ul li {
  margin-right: 0.5em;
  margin-bottom: 0.5em;
  background: #fff;
  border-top: 0.1em solid #ccc;
  border-right: 0.2em solid #ccc;
  border-bottom: 0.2em solid #ccc;
  border-left: 0.1em solid #ccc;
  padding: 0.5em;
  white-space: nowrap;
}